import { Problem, ProblemLanguages, ProblemTypes } from '../types';

export const ceasarProblems: { [id: string]: Problem[] } = {
  en: [
    {
      type: ProblemTypes.CEASAR,

      title: 'Daddy Long Legs',

      language: ProblemLanguages.EN,

      plainText:
        'THEFIRSTWEDNESDAYINEVERYMONTHWASAPERFECTLYAWFULDAYADAYTOBEAWAITEDWITHDREADENDUREDWITHCOURAGEANDFORGOTTENWITHHASTEEVERYFLOORMUSTBESPOTLESSEVERYCHAIRDUSTLESSANDEVERYBEDWITHOUTAWRINKLENINETYSEVENSQUIRMINGLITTLEORPHANSMUSTBESCRUBBEDANDCOMBEDANDBUTTONEDINTOFRESHLYSTARCHEDGINGHAMSANDALLNINETYSEVENREMINDEDOFTHEIRMANNERSANDTOLDTOSAYYESSIRNOSIRWHENEVERATRUSTEESPOKE',

      cipherText:
        'WKHILUVWZHGQHVGDBLQHYHUBPRQWKZDVDSHUIHFWOBDZIXOGDBDGDBWREHDZDLWHGZLWKGUHDGHQGXUHGZLWKFRXUDJHDQGIRUJRWWHQZLWKKDVWHHYHUBIORRUPXVWEHVSRWOHVVHYHUBFKDLUGXVWOHVVDQGHYHUBEHGZLWKRXWDZULQNOHQLQHWBVHYHQVTXLUPLQJOLWWOHRUSKDQVPXVWEHVFUXEEHGDQGFRPEHGDQGEXWWRQHGLQWRIUHVKOBVWDUFKHGJLQJKDPVDQGDOOQLQHWBVHYHQUHPLQGHGRIWKHLUPDQQHUVDQGWROGWRVDBBHVVLUQRVLUZKHQHYHUDWUXVWHHVSRNH',

      // key: 3
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Human Rights 1',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDTOALLTHERIGHTSANDFREEDOMSSETFORTHINTHISDECLARATIONWITHOUTDISTINCTIONOFANYKINDSUCHASRACECOLOURSEXLANGUAGERELIGIONPOLITICALOROTHEROPINIONNATIONALORSOCIALORIGINPROPERTYBIRTHOROTHERSTATUSFURTHERMORENODISTINCTIONSHALLBEMADEONTHEBASISOFTHEPOLITICALJURISDICTIONALORINTERNATIONALSTATUSOFTHECOUNTRYORTERRITORYTOWHICHAPERSONBELONGSWHETHERITBEINDEPENDENTTRUSTNONSELFGOVERNINGORUNDERANYOTHERLIMITATIONOFSOVEREIGNTY',

      cipherText:
        'IZIVCSRIMWIRXMXPIHXSEPPXLIVMKLXWERHJVIIHSQWWIXJSVXLMRXLMWHIGPEVEXMSRAMXLSYXHMWXMRGXMSRSJERCOMRHWYGLEWVEGIGSPSYVWIBPERKYEKIVIPMKMSRTSPMXMGEPSVSXLIVSTMRMSRREXMSREPSVWSGMEPSVMKMRTVSTIVXCFMVXLSVSXLIVWXEXYWJYVXLIVQSVIRSHMWXMRGXMSRWLEPPFIQEHISRXLIFEWMWSJXLITSPMXMGEPNYVMWHMGXMSREPSVMRXIVREXMSREPWXEXYWSJXLIGSYRXVCSVXIVVMXSVCXSALMGLETIVWSRFIPSRKWALIXLIVMXFIMRHITIRHIRXXVYWXRSRWIPJKSZIVRMRKSVYRHIVERCSXLIVPMQMXEXMSRSJWSZIVIMKRXC',

      // key: 4
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Human Rights 2',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDINFULLEQUALITYTOAFAIRANDPUBLICHEARINGBYANINDEPENDENTANDIMPARTIALTRIBUNALINTHEDETERMINATIONOFHISRIGHTSANDOBLIGATIONSANDOFANYCRIMINALCHARGEAGAINSTHIM',

      cipherText:
        'RIRELBARVFRAGVGYRQVASHYYRDHNYVGLGBNSNVENAQCHOYVPURNEVATOLNAVAQRCRAQRAGNAQVZCNEGVNYGEVOHANYVAGURQRGREZVANGVBABSUVFEVTUGFNAQBOYVTNGVBAFNAQBSNALPEVZVANYPUNETRNTNVAFGUVZ',

      // key: 13
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Human Rights 3',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEHASTHERIGHTTOFREEDOMOFOPINIONANDEXPRESSIONTHISRIGHTINCLUDESFREEDOMTOHOLDOPINIONSWITHOUTINTERFERENCEANDTOSEEKRECEIVEANDIMPARTINFORMATIONANDIDEASTHROUGHANYMEDIAANDREGARDLESSOFFRONTIERS',

      cipherText:
        'YPYLSIHYBUMNBYLCABNNIZLYYXIGIZIJCHCIHUHXYRJLYMMCIHNBCMLCABNCHWFOXYMZLYYXIGNIBIFXIJCHCIHMQCNBIONCHNYLZYLYHWYUHXNIMYYELYWYCPYUHXCGJULNCHZILGUNCIHUHXCXYUMNBLIOABUHSGYXCUUHXLYAULXFYMMIZZLIHNCYLM',

      // key: 20
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Moby Dick',

      language: ProblemLanguages.EN,

      plainText:
        'SOMEYEARSAGONEVERMINDHOWLONGPRECISELYHAVINGLITTLEORNOMONEYINMYPURSEANDNOTHINGPARTICULARTOINTERESTMEONSHOREITHOUGHTIWOULDSAILABOUTALITTLEANDSEETHEWATERYPARTOFTHEWORLDITISAWAYIHAVEOFDRIVINGOFFTHESPLEENANDREGULATINGTHECIRCULATIONWHENEVERIFINDMYSELFGROWINGGRIMABOUTTHEMOUTHWHENEVERITISADAMPDRIZZLYNOVEMBERINMYSOULWHENEVERIFINDMYSELFINVOLUNTARILYPAUSINGBEFORECOFFINWAREHOUSESANDBRINGINGUPTHEREAROFEVERYFUNERALIMEETANDESPECIALLYWHENEVERMYHYPOSGETSUCHANUPPERHANDOFMETHATITREQUIRESASTRONGMORALPRINCIPLETOPREVENTMEFROMDELIBERATELYSTEPPINGINTOTHESTREETANDMETHODICALLYKNOCKINGPEOPLESHATSOFFTHENIACCOUNTITHIGHTIMETOGETTOSEAASSOONASICAN',

      cipherText:
        'KGEWQWSJKSYGFWNWJEAFVZGODGFYHJWUAKWDQZSNAFYDALLDWGJFGEGFWQAFEQHMJKWSFVFGLZAFYHSJLAUMDSJLGAFLWJWKLEWGFKZGJWALZGMYZLAOGMDVKSADSTGMLSDALLDWSFVKWWLZWOSLWJQHSJLGXLZWOGJDVALAKSOSQAZSNWGXVJANAFYGXXLZWKHDWWFSFVJWYMDSLAFYLZWUAJUMDSLAGFOZWFWNWJAXAFVEQKWDXYJGOAFYYJAESTGMLLZWEGMLZOZWFWNWJALAKSVSEHVJARRDQFGNWETWJAFEQKGMDOZWFWNWJAXAFVEQKWDXAFNGDMFLSJADQHSMKAFYTWXGJWUGXXAFOSJWZGMKWKSFVTJAFYAFYMHLZWJWSJGXWNWJQXMFWJSDAEWWLSFVWKHWUASDDQOZWFWNWJEQZQHGKYWLKMUZSFMHHWJZSFVGXEWLZSLALJWIMAJWKSKLJGFYEGJSDHJAFUAHDWLGHJWNWFLEWXJGEVWDATWJSLWDQKLWHHAFYAFLGLZWKLJWWLSFVEWLZGVAUSDDQCFGUCAFYHWGHDWKZSLKGXXLZWFASUUGMFLALZAYZLAEWLGYWLLGKWSSKKGGFSKAUSF',

      // key: 18
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'The Little Prince',

      language: ProblemLanguages.EN,

      plainText:
        'SOILIVEDMYLIFEALONEWITHOUTANYONETHATICOULDREALLYTALKTOUNTILIHADANACCIDENTWITHMYPLANEINTHEDESERTOFSAHARASIXYEARSAGOSOMETHINGWASBROKENINMYENGINEANDASIHADWITHMENEITHERAMECHANICNORANYPASSENGERSISETMYSELFTOATTEMPTTHEDIFFICULTREPAIRSALLALONEITWASAQUESTIONOFLIFEORDEATHFORMEIHADSCARCELYENOUGHDRINKINGWATERTOLASTAWEEKTHEFIRSTNIGHTTHENIWENTTOSLEEPONTHESANDATHOUSANDMILESFROMANYHUMANHABITATIONIWASMOREISOLATEDTHANASHIPWRECKEDSAILORONARAFTINTHEMIDDLEOFTHEOCEANTHUSYOUCANIMAGINEMYAMAZEMENTATSUNRISEWHENIWASAWAKENEDBYANODDLITTLEVOICE',

      cipherText:
        'TPJMJWFENZMJGFBMPOFXJUIPVUBOZPOFUIBUJDPVMESFBMMZUBMLUPVOUJMJIBEBOBDDJEFOUXJUINZQMBOFJOUIFEFTFSUPGTBIBSBTJYZFBSTBHPTPNFUIJOHXBTCSPLFOJONZFOHJOFBOEBTJIBEXJUINFOFJUIFSBNFDIBOJDOPSBOZQBTTFOHFSTJTFUNZTFMGUPBUUFNQUUIFEJGGJDVMUSFQBJSTBMMBMPOFJUXBTBRVFTUJPOPGMJGFPSEFBUIGPSNFJIBETDBSDFMZFOPVHIESJOLJOHXBUFSUPMBTUBXFFLUIFGJSTUOJHIUUIFOJXFOUUPTMFFQPOUIFTBOEBUIPVTBOENJMFTGSPNBOZIVNBOIBCJUBUJPOJXBTNPSFJTPMBUFEUIBOBTIJQXSFDLFETBJMPSPOBSBGUJOUIFNJEEMFPGUIFPDFBOUIVTZPVDBOJNBHJOFNZBNBAFNFOUBUTVOSJTFXIFOJXBTBXBLFOFECZBOPEEMJUUMFWPJDF',

      // key: 1
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Sherlock Holmes',

      language: ProblemLanguages.EN,

      plainText:
        'SHERLOCKHOLMESWASTRANSFORMEDWHENHEWASHOTUPONSUCHASCENTASTHISMENWHOHADONLYKNOWNTHEQUIETTHINKERANDLOGICIANOFBAKERSTREETWOULDHAVEFAILEDTORECOGNISEHIMHISFACEFLUSHEDANDDARKENEDHISBROWSWEREDRAWNINTOTWOHARDBLACKLINESWHILEHISEYESSHONEOUTFROMBENEATHTHEMWITHASTEELYGLITTERHISFACEWASBENTDOWNWARDHISSHOULDERSBOWEDHISLIPSCOMPRESSEDANDTHEVEINSSTOODOUTLIKEWHIPCORDINHISLONGSINEWYNECKHISNOSTRILSSEEMEDTODILATEWITHAPURELYANIMALLUSTFORTHECHASEANDHISMINDWASSOABSOLUTELYCONCENTRATEDUPONTHEMATTERBEFOREHIMTHATAQUESTIONORREMARKFELLUNHEEDEDUPONHISEARSORATTHEMOSTONLYPROVOKEDAQUICKIMPATIENTSNARLINREPLYSWIFTLYANDSILENTLYHEMADEHISWAYALONGTHETRACKWHICHRANTHROUGHTHEMEADOWSANDSOBYWAYOFTHEWOODSTOTHEBOSCOMBEPOOLITWASDAMPMARSHYGROUNDASISALLTHATDISTRICTANDTHEREWEREMARKSOFMANYFEETBOTHUPONTHEPATHANDAMIDTHESHORTGRASSWHICHBOUNDEDITONEITHERSIDESOMETIMESHOLMESWOULDHURRYONSOMETIMESSTOPDEADANDONCEHEMADEQUITEALITTLEDETOURINTOTHEMEADOW',

      cipherText:
        'CROBVYMURYVWOCGKCDBKXCPYBWONGROXROGKCRYDEZYXCEMRKCMOXDKCDRSCWOXGRYRKNYXVIUXYGXDROAESODDRSXUOBKXNVYQSMSKXYPLKUOBCDBOODGYEVNRKFOPKSVONDYBOMYQXSCORSWRSCPKMOPVECRONKXNNKBUOXONRSCLBYGCGOBONBKGXSXDYDGYRKBNLVKMUVSXOCGRSVORSCOIOCCRYXOYEDPBYWLOXOKDRDROWGSDRKCDOOVIQVSDDOBRSCPKMOGKCLOXDNYGXGKBNRSCCRYEVNOBCLYGONRSCVSZCMYWZBOCCONKXNDROFOSXCCDYYNYEDVSUOGRSZMYBNSXRSCVYXQCSXOGIXOMURSCXYCDBSVCCOOWONDYNSVKDOGSDRKZEBOVIKXSWKVVECDPYBDROMRKCOKXNRSCWSXNGKCCYKLCYVEDOVIMYXMOXDBKDONEZYXDROWKDDOBLOPYBORSWDRKDKAEOCDSYXYBBOWKBUPOVVEXROONONEZYXRSCOKBCYBKDDROWYCDYXVIZBYFYUONKAESMUSWZKDSOXDCXKBVSXBOZVICGSPDVIKXNCSVOXDVIROWKNORSCGKIKVYXQDRODBKMUGRSMRBKXDRBYEQRDROWOKNYGCKXNCYLIGKIYPDROGYYNCDYDROLYCMYWLOZYYVSDGKCNKWZWKBCRIQBYEXNKCSCKVVDRKDNSCDBSMDKXNDROBOGOBOWKBUCYPWKXIPOODLYDREZYXDROZKDRKXNKWSNDROCRYBDQBKCCGRSMRLYEXNONSDYXOSDROBCSNOCYWODSWOCRYVWOCGYEVNREBBIYXCYWODSWOCCDYZNOKNKXNYXMOROWKNOAESDOKVSDDVONODYEBSXDYDROWOKNYG',

      // key: 10
    },
  ],
  de: [
    {
      type: ProblemTypes.CEASAR,

      title: 'Im tropischen Busch',

      language: ProblemLanguages.DE,

      plainText:
        'UNDURCHDRINGLICHERDSCHUNGELBEDECKTDIEWEITENEBENENDERFLUSSGEBIETEDESPANUCOUNDDESTAMESIZWEIBAHNLINIENNURDURCHZIEHENDIESENNEUNZIGTAUSENDQUADRATKILOMETERGROSSENTEILDERTIERRACALIENTEWOSICHANSIEDELUNGENBEFINDENHABENSIESICHDICHTUNDNGSTLICHANDIEWENIGENEISENBAHNSTATIONENGEDRAENGTEUROPAEERWOHNENHIERNURGANZVEREINZELTUNDWIEVERLORENDIEERMUEDENDEGLEICHFOERMIGKEITDESDSCHUNGELSWIRDVONEINIGENSICHLANGHINSTRECKENDENHOEHENZUEGENUNTERBROCHENDIEMITTROPISCHEMURBUSCHBEWACHSENSINDDEREBENSOUNDURCHDRINGLICHISTWIEDERDSCHUNGELUNDINDESSENTIEFENWOIMMERDAEMMERUNGHERRSCHTALLEMYSTERIENUNDGRAUENDERWELTZULAUERNSCHEINEN',

      cipherText:
        'XQGXUFKGULQJOLFKHUGVFKXQJHOEHGHFNWGLHZHLWHQHEHQHQGHUIOXVVJHELHWHGHVSDQXFRXQGGHVWDPHVLCZHLEDKQOLQLHQQXUGXUFKCLHKHQGLHVHQQHXQCLJWDXVHQGTXDGUDWNLORPHWHUJURVVHQWHLOGHUWLHUUDFDOLHQWHZRVLFKDQVLHGHOXQJHQEHILQGHQKDEHQVLHVLFKGLFKWXQGQJVWOLFKDQGLHZHQLJHQHLVHQEDKQVWDWLRQHQJHGUDHQJWHXURSDHHUZRKQHQKLHUQXUJDQCYHUHLQCHOWXQGZLHYHUORUHQGLHHUPXHGHQGHJOHLFKIRHUPLJNHLWGHVGVFKXQJHOVZLUGYRQHLQLJHQVLFKODQJKLQVWUHFNHQGHQKRHKHQCXHJHQXQWHUEURFKHQGLHPLWWURSLVFKHPXUEXVFKEHZDFKVHQVLQGGHUHEHQVRXQGXUFKGULQJOLFKLVWZLHGHUGVFKXQJHOXQGLQGHVVHQWLHIHQZRLPPHUGDHPPHUXQJKHUUVFKWDOOHPBVWHULHQXQGJUDXHQGHUZHOWCXODXHUQVFKHLQHQ',

      // key: 3
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Menschenrechte 1',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFDIEINDIESERERKLAERUNGVERKUENDETENRECHTEUNDFREIHEITENOHNEIRGENDEINENUNTERSCHIEDETWANACHRASSEHAUTFARBEGESCHLECHTSPRACHERELIGIONPOLITISCHERODERSONSTIGERUEBERZEUGUNGNATIONALERODERSOZIALERHERKUNFTVERMOEGENGEBURTODERSONSTIGEMSTANDDESWEITERENDARFKEINUNTERSCHIEDGEMACHTWERDENAUFGRUNDDERPOLITISCHENRECHTLICHENODERINTERNATIONALENSTELLUNGDESLANDESODERGEBIETSDEMEINEPERSONANGEHOERTGLEICHGUELTIGOBDIESESUNABHAENGIGISTUNTERTREUHANDSCHAFTSTEHTKEINESELBSTREGIERUNGBESITZTODERSONSTINSEINERSOUVERAENITAETEINGESCHRAENKTIST',

      cipherText:
        'PKJKXNGZGTYVXAINGALJOKOTJOKYKXKXQRGKXATMBKXQAKTJKZKTXKINZKATJLXKONKOZKTUNTKOXMKTJKOTKTATZKXYINOKJKZCGTGINXGYYKNGAZLGXHKMKYINRKINZYVXGINKXKROMOUTVUROZOYINKXUJKXYUTYZOMKXAKHKXFKAMATMTGZOUTGRKXUJKXYUFOGRKXNKXQATLZBKXSUKMKTMKHAXZUJKXYUTYZOMKSYZGTJJKYCKOZKXKTJGXLQKOTATZKXYINOKJMKSGINZCKXJKTGALMXATJJKXVUROZOYINKTXKINZROINKTUJKXOTZKXTGZOUTGRKTYZKRRATMJKYRGTJKYUJKXMKHOKZYJKSKOTKVKXYUTGTMKNUKXZMRKOINMAKRZOMUHJOKYKYATGHNGKTMOMOYZATZKXZXKANGTJYINGLZYZKNZQKOTKYKRHYZXKMOKXATMHKYOZFZUJKXYUTYZOTYKOTKXYUABKXGKTOZGKZKOTMKYINXGKTQZOYZ',

      // key: 6
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Menschenrechte 2',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFEINENWIRKSAMENRECHTSBEHELFBEIDENZUSTANDIGENINNERSTAATLICHENGERICHTENGEGENHANDLUNGENDURCHDIESEINEIHMNACHDERVERFASSUNGODERNACHDEMGESETZZUSTEHENDENGRUNDRECHTEVERLETZTWERDEN',

      cipherText:
        'FAZANDWPWJOLNQYDWQBAEJAJSENGOWIAJNAYDPOXADAHBXAEZAJVQOPWJZECAJEJJANOPWWPHEYDAJCANEYDPAJCACAJDWJZHQJCAJZQNYDZEAOAEJAEDIJWYDZANRANBWOOQJCKZANJWYDZAICAOAPVVQOPADAJZAJCNQJZNAYDPARANHAPVPSANZAJ',

      // key: 22
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Menschenrechte 3',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATDASRECHTAUFMEINUNGSFREIHEITUNDFREIEMEINUNGSAUSSERUNGDIESESRECHTSCHLIESSTDIEFREIHEITEINMEINUNGENUNGEHINDERTANZUHANGENSOWIEUBERMEDIENJEDERARTUNDOHNERUCKSICHTAUFGRENZENINFORMATIONENUNDGEDANKENGUTZUSUCHENZUEMPFANGENUNDZUVERBREITEN',

      cipherText:
        'GBABOEXQAXPOBZEQXRCJBFKRKDPCOBFEBFQRKACOBFBJBFKRKDPXRPPBORKDAFBPBPOBZEQPZEIFBPPQAFBCOBFEBFQBFKJBFKRKDBKRKDBEFKABOQXKWREXKDBKPLTFBRYBOJBAFBKGBABOXOQRKALEKBORZHPFZEQXRCDOBKWBKFKCLOJXQFLKBKRKADBAXKHBKDRQWRPRZEBKWRBJMCXKDBKRKAWRSBOYOBFQBK',

      // key: 23
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Moby Dick',

      language: ProblemLanguages.DE,

      plainText:
        'EINPAARJAHREISTSHERUNWICHTIGWIELANGGENAUDAHATTEICHWENIGBISGARKEINGELDIMBEUTELUNDANLANDREIZTEMICHNICHTSBESONDERESUNDSODACHTICHMIRICHWOLLTEINWENIGHERUMSEGELNUNDMIRDENWASSERIGENTEILDERWELTBESEHENDASISTSOMEINEARTMIRDIEMILZSUCHTZUVERTREIBENUNDDENKREISLAUFINSCHWUNGZUBRINGENIMMERWENNICHMERKEDASSICHUMDENMUNDHERUMGRIMMIGWERDEIMMERWENNINMEINERSEELENASSERNIESLIGERNOVEMBERHERRSCHTIMMERWENNICHMERKEDASSICHVORSARGLAGERNSTEHENBLEIBEUNDJEDEMLEICHENZUGHINTERHERTROTTEDERMIRBEGEGNETUNDBESONDERSIMMERDANNWENNMEINESCHWARZEGALLESOSEHRUBERHANDNIMMTDASSNURSTARKEMORALISCHEGRUNDSATZEMICHDAVONABHALTENKONNENMITVORSATZAUFDIESTRASSEZUTRETENUNDDENLEUTENMITBEDACHTDIEHUTEVOMKOPFZUHAUENDANNISTESHOCHSTEZEITFURMICHSOBALDICHKANNAUFSEEZUKOMMEN',

      cipherText:
        'TXCEPPGYPWGTXHIHWTGJCLXRWIXVLXTAPCVVTCPJSPWPIITXRWLTCXVQXHVPGZTXCVTASXBQTJITAJCSPCAPCSGTXOITBXRWCXRWIHQTHDCSTGTHJCSHDSPRWIXRWBXGXRWLDAAITXCLTCXVWTGJBHTVTACJCSBXGSTCLPHHTGXVTCITXASTGLTAIQTHTWTCSPHXHIHDBTXCTPGIBXGSXTBXAOHJRWIOJKTGIGTXQTCJCSSTCZGTXHAPJUXCHRWLJCVOJQGXCVTCXBBTGLTCCXRWBTGZTSPHHXRWJBSTCBJCSWTGJBVGXBBXVLTGSTXBBTGLTCCXCBTXCTGHTTATCPHHTGCXTHAXVTGCDKTBQTGWTGGHRWIXBBTGLTCCXRWBTGZTSPHHXRWKDGHPGVAPVTGCHITWTCQATXQTJCSYTSTBATXRWTCOJVWXCITGWTGIGDIITSTGBXGQTVTVCTIJCSQTHDCSTGHXBBTGSPCCLTCCBTXCTHRWLPGOTVPAATHDHTWGJQTGWPCSCXBBISPHHCJGHIPGZTBDGPAXHRWTVGJCSHPIOTBXRWSPKDCPQWPAITCZDCCTCBXIKDGHPIOPJUSXTHIGPHHTOJIGTITCJCSSTCATJITCBXIQTSPRWISXTWJITKDBZDEUOJWPJTCSPCCXHITHWDRWHITOTXIUJGBXRWHDQPASXRWZPCCPJUHTTOJZDBBTC',

      // key: 15
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Der kleine Prinz',

      language: ProblemLanguages.DE,

      plainText:
        'SOLEBTEICHALLEINOHNEJEMANDENMITDEMICHWIRKLICHHATTESPRECHENKONNENBISICHVORSECHSJAHRENEINEPANNEINDERWUSTESAHARAHATTEIRGENDETWASANMEINEMMOTORWARKAPUTTGEGANGENUNDWEILICHWEDEREINENMECHANIKERNOCHPASSAGIEREBEIMIRHATTEBEREITETEICHMICHDARAUFVORDIESCHWIERIGEREPARATURGANZALLEINVORZUNEHMENDABEIGINGESFURMICHUMLEBENUNDTODICHHATTETRINKWASSERFURKNAPPACHTTAGEAMERSTENABENDSCHLIEFICHIMSANDEINTAUSENDEMEILENENTFERNTVONBESIEDELTEMGEBIETICHWARVIELABGESCHIEDENERALSEINSCHIFFBRUCHIGERMITTENIMOZEANSOKONNTIHREUCHVORSTELLENWIEUBERRASCHTICHWARALSICHBEISONNENAUFGANGVONEINERLEISENLUSTIGENSTIMMEGEWECKTWURDE',

      cipherText:
        'CYVOLDOSMRKVVOSXYRXOTOWKXNOXWSDNOWSMRGSBUVSMRRKDDOCZBOMROXUYXXOXLSCSMRFYBCOMRCTKRBOXOSXOZKXXOSXNOBGECDOCKRKBKRKDDOSBQOXNODGKCKXWOSXOWWYDYBGKBUKZEDDQOQKXQOXEXNGOSVSMRGONOBOSXOXWOMRKXSUOBXYMRZKCCKQSOBOLOSWSBRKDDOLOBOSDODOSMRWSMRNKBKEPFYBNSOCMRGSOBSQOBOZKBKDEBQKXJKVVOSXFYBJEXORWOXNKLOSQSXQOCPEBWSMREWVOLOXEXNDYNSMRRKDDODBSXUGKCCOBPEBUXKZZKMRDDKQOKWOBCDOXKLOXNCMRVSOPSMRSWCKXNOSXDKECOXNOWOSVOXOXDPOBXDFYXLOCSONOVDOWQOLSODSMRGKBFSOVKLQOCMRSONOXOBKVCOSXCMRSPPLBEMRSQOBWSDDOXSWYJOKXCYUYXXDSRBOEMRFYBCDOVVOXGSOELOBBKCMRDSMRGKBKVCSMRLOSCYXXOXKEPQKXQFYXOSXOBVOSCOXVECDSQOXCDSWWOQOGOMUDGEBNO',

      // key: 10
    },
    {
      type: ProblemTypes.CEASAR,

      title: 'Sherlock Holmes',

      language: ProblemLanguages.DE,

      plainText:
        'SHERLOCKHOLMESWARWIEVERWANDELTWENNERAUFEINESOLCHESPURSTIESSMENSCHENDIENURDENSTILLENDENKERUNDLOGIKERAUSDERBAKERSTREETKANNTENHTTENIHNNICHTERKANNTSEINGESICHTERRTETEUNDVERDUNKELTESICHSEINEBRAUENWARENZUZWEIHARTENSCHWARZENLINIENGEZOGENWHRENDSEINEAUGENDARUNTERMITEINEMSTHLERNENGLITZERNHERVORLEUCHTETENSEINGESICHTWARNACHUNTENGEBOGENDIESCHULTERNGEBEUGTDIELIPPENZUSAMMENGEPRESSTUNDDIEADERNTRATENWIEPEITSCHENSCHNREINSEINEMLANGENSEHNIGENHALSHERVORSEINENASENLCHERSCHIENENSICHMITEINERREINTIERISCHENLUSTANDERJAGDZUWEITENUNDSEINGEISTWARSOABSOLUTAUFDIESACHEKONZENTRIERTDIEERVORSICHHATTEDASSEINEFRAGEODERBEMERKUNGUNGEHRTBLIEBODERHCHSTENSEINSCHNELLESUNGEDULDIGESKNURRENALSANTWORTHERVORRIEFZGIGUNDLAUTLOSSCHLUGERDENWEGEINDERDURCHDIEWIESENUNDDAMITDURCHDENWALDZUMBOSCOMBEPOOLFHRTEESWARFEUCHTERSUMPFIGERBODENWIEBERALLINDIESERGEGENDUNDESGABVIELEFUSSSPURENSOWOHLAUFDEMWEGALSAUCHINDEMKURZENGRASDASIHNAUFBEIDENSEITENBEGRENZTEMANCHMALEILTEHOLMESWEITERMANCHMALBLIEBERSTEHENUNDEINMALMACHTEEREINENKLEINENUMWEGBERDIEWIESE',

      cipherText:
        'IXUHBESAXEBCUIMQHMYULUHMQDTUBJMUDDUHQKVUYDUIEBSXUIFKHIJYUIICUDISXUDTYUDKHTUDIJYBBUDTUDAUHKDTBEWYAUHQKITUHRQAUHIJHUUJAQDDJUDXJJUDYXDDYSXJUHAQDDJIUYDWUIYSXJUHHJUJUKDTLUHTKDAUBJUIYSXIUYDURHQKUDMQHUDPKPMUYXQHJUDISXMQHPUDBYDYUDWUPEWUDMXHUDTIUYDUQKWUDTQHKDJUHCYJUYDUCIJXBUHDUDWBYJPUHDXUHLEHBUKSXJUJUDIUYDWUIYSXJMQHDQSXKDJUDWUREWUDTYUISXKBJUHDWURUKWJTYUBYFFUDPKIQCCUDWUFHUIIJKDTTYUQTUHDJHQJUDMYUFUYJISXUDISXDHUYDIUYDUCBQDWUDIUXDYWUDXQBIXUHLEHIUYDUDQIUDBSXUHISXYUDUDIYSXCYJUYDUHHUYDJYUHYISXUDBKIJQDTUHZQWTPKMUYJUDKDTIUYDWUYIJMQHIEQRIEBKJQKVTYUIQSXUAEDPUDJHYUHJTYUUHLEHIYSXXQJJUTQIIUYDUVHQWUETUHRUCUHAKDWKDWUXHJRBYURETUHXSXIJUDIUYDISXDUBBUIKDWUTKBTYWUIADKHHUDQBIQDJMEHJXUHLEHHYUVPWYWKDTBQKJBEIISXBKWUHTUDMUWUYDTUHTKHSXTYUMYUIUDKDTTQCYJTKHSXTUDMQBTPKCREISECRUFEEBVXHJUUIMQHVUKSXJUHIKCFVYWUHRETUDMYURUHQBBYDTYUIUHWUWUDTKDTUIWQRLYUBUVKIIIFKHUDIEMEXBQKVTUCMUWQBIQKSXYDTUCAKHPUDWHQITQIYXDQKVRUYTUDIUYJUDRUWHUDPJUCQDSXCQBUYBJUXEBCUIMUYJUHCQDSXCQBRBYURUHIJUXUDKDTUYDCQBCQSXJUUHUYDUDABUYDUDKCMUWRUHTYUMYUIU',

      // key: 16
    },
  ],
};
