import { Problem, ProblemLanguages, ProblemTypes } from '../types';

export const vigenereProblems: { [id: string]: Problem[] } = {
  de: [
    {
      type: ProblemTypes.VIGENERE,

      title: 'Im tropischen Busch',

      language: ProblemLanguages.DE,

      plainText:
        'UNDURCHDRINGLICHERDSCHUNGELBEDECKTDIEWEITENEBENENDERFLUSSGEBIETEDESPANUCOUNDDESTAMESIZWEIBAHNLINIENNURDURCHZIEHENDIESENNEUNZIGTAUSENDQUADRATKILOMETERGROSSENTEILDERTIERRACALIENTEWOSICHANSIEDELUNGENBEFINDENHABENSIESICHDICHTUNDNGSTLICHANDIEWENIGENEISENBAHNSTATIONENGEDRAENGTEUROPAEERWOHNENHIERNURGANZVEREINZELTUNDWIEVERLORENDIEERMUEDENDEGLEICHFOERMIGKEITDESDSCHUNGELSWIRDVONEINIGENSICHLANGHINSTRECKENDENHOEHENZUEGENUNTERBROCHENDIEMITTROPISCHEMURBUSCHBEWACHSENSINDDEREBENSOUNDURCHDRINGLICHISTWIEDERDSCHUNGELUNDINDESSENTIEFENWOIMMERDAEMMERUNGHERRSCHTALLEMYSTERIENUNDGRAUENDERWELTZULAUERNSCHEINEN',

      cipherText:
        'LAUHIPYQIVETCVTUVEUFTULAXRCOVQVPBGUVVJVVKRERSREREQVEWYLFJTVOZRKRURJCRALPFHEQURJGRZVFZMNRZORUEYZAZREALEUHIPYMZRYREQZRJREAVHEMZTKNLFVAUDLNUERGBVCBDRKRITIBJFVAKRZYURIGZRIERPRYZREGVJFFZPYNEFZRURCHETVASRWVEQVAYNSREFZRJVTUUVTUKHEQETJGCVTURAUVVJVAZTVAVVJREORUEFKNKVFAVAXRUERRETKRLEFCRRVENBYAVAYVVEEHITRAQIVEVVEMVYKHEQNVVIVECBIREQZRVEDHVQVAURXYVVTUWBVEDVXXVVKQVFUFTULAXRCFNVIQMBERZAZTVAJVTUCNETYVEFKEVPBREQVAYBVUVAQHVTVALAKRIOIBTUVAUVVZZGKEFCZFTUVZLESHJPYOVJRPYFVAJVEQURIRSREFFHEQLETUUEZAXYZPYVJGNVVQVEUFTULAXRCHEQZAURJFVAKVVSVANBZZDRIQRRDZVELAXUVEIFTUKNCYVZPFKRIVVALAUTINLREQVENRCGQHCNLRIAJPYRZAVA',

      // key: RN
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Menschenrechte 1',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFDIEINDIESERERKLAERUNGVERKUENDETENRECHTEUNDFREIHEITENOHNEIRGENDEINENUNTERSCHIEDETWANACHRASSEHAUTFARBEGESCHLECHTSPRACHERELIGIONPOLITISCHERODERSONSTIGERUEBERZEUGUNGNATIONALERODERSOZIALERHERKUNFTVERMOEGENGEBURTODERSONSTIGEMSTANDDESWEITERENDARFKEINUNTERSCHIEDGEMACHTWERDENAUFGRUNDDERPOLITISCHENRECHTLICHENODERINTERNATIONALENSTELLUNGDESLANDESODERGEBIETSDEMEINEPERSONANGEHOERTGLEICHGUELTIGOBDIESESUNABHAENGIGISTUNTERTREUHANDSCHAFTSTEHTKEINESELBSTREGIERUNGBESITZTODERSONSTINSEINERSOUVERAENITAETEINGESCHRAENKTIST',

      cipherText:
        'BQEJJTBYSZTUJGDMSGGIAQJSVUFXWDFWCXBJJGOLNQSPMQOIWFFSJQDMLQVSVRSJATFNLQOTZZFNJSFSVQJSWZVSLQSXUTJJVQUBSZBHZDBXKQIFMFGFJNFLWEDMDQDMLEQWSOIJJQMNYUPSHAMNLUTHZQSTVQSXGZTYASFWMQCJJLFZYGOLFMUNGZBQWDPIWDTTRUBQWDIJJWVSXFWJJYPJYQOLWNVWLAEJJEPSKFJLWYTYSZEIWEXJAFFWWZEFJRLJAZVSLQSXUTJJVSFRSOIYOQSIWZBZXSSZFPEJJBPQAFJXUTFSJQDMLXJHZQOTVQSNFFFWFMUNGZBQWZTYWXMZFSEJKXBSVQTTVQSLWNJJLEEJEQJSWBFWKAOFFSFMGQSYYXFNUTHZWXUNYACIAQTJKGOFTTBJFSJLAEUZFFFWLDFZZMOIKOIFXFTYWTUPWUOJKQMGKFSJYUFWMZHGWEJYRFPIWDTTFEUNFEFNFQSXGGWJJMFSAFBJLQJSYQTHZDBJFWUNKF',

      // key: SMBF
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Menschenrechte 2',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFEINENWIRKSAMENRECHTSBEHELFBEIDENZUSTANDIGENINNERSTAATLICHENGERICHTENGEGENHANDLUNGENDURCHDIESEINEIHMNACHDERVERFASSUNGODERNACHDEMGESETZZUSTEHENDENGRUNDRECHTEVERLETZTWERDEN',

      cipherText:
        'KWEWSZBLBFTHSMDZBMGWJFFFXASCTSNWOJFUILTTFZFDGTFAEWORVKUSOVJYFFJFOWSKUSBLMADZFFHWSADZUWOYFYFFISOVMMOYFFEMSUIVJWTWJFFAIEOSDZEWSNFJGSTKVFHGEWSFBUIVFEHWTWURAMTLFZFFEWOYSMOVSWDZUWWWSDFLALXWSVFF',

      // key: BS
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Menschenrechte 3',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATDASRECHTAUFMEINUNGSFREIHEITUNDFREIEMEINUNGSAUSSERUNGDIESESRECHTSCHLIESSTDIEFREIHEITEINMEINUNGENUNGEHINDERTANZUHANGENSOWIEUBERMEDIENJEDERARTUNDOHNERUCKSICHTAUFGRENZENINFORMATIONENUNDGEDANKENGUTZUSUCHENZUEMPFANGENUNDZUVERBREITEN',

      cipherText:
        'HKICXMYZIYYWCIMRGZDSJGTZLMXDXJGNJGZZLJKPKNCSJGTZLMXYAXQKWSTLBOJQKXPKHFZXANQGKXQZIGKKPKNFKNRKNLSJGTZLMJLASEKMGTICXYYTESNFLMJLYTUOJSHJPSJBOJLPJBKWYXYSTIMNSCXZAQXGIMRGZDMWCTECTNLLTPSFROTLKSSTIEKIYTPCTLSZESYZANJLFZCSUDGSEKSSTIXAACXGPKNRKS',

      // key: YGF
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Moby Dick',

      language: ProblemLanguages.DE,

      plainText:
        'EINPAARJAHREISTSHERUNWICHTIGWIELANGGENAUDAHATTEICHWENIGBISGARKEINGELDIMBEUTELUNDANLANDREIZTEMICHNICHTSBESONDERESUNDSODACHTICHMIRICHWOLLTEINWENIGHERUMSEGELNUNDMIRDENWASSERIGENTEILDERWELTBESEHENDASISTSOMEINEARTMIRDIEMILZSUCHTZUVERTREIBENUNDDENKREISLAUFINSCHWUNGZUBRINGENIMMERWENNICHMERKEDASSICHUMDENMUNDHERUMGRIMMIGWERDEIMMERWENNINMEINERSEELENASSERNIESLIGERNOVEMBERHERRSCHTIMMERWENNICHMERKEDASSICHVORSARGLAGERNSTEHENBLEIBEUNDJEDEMLEICHENZUGHINTERHERTROTTEDERMIRBEGEGNETUNDBESONDERSIMMERDANNWENNMEINESCHWARZEGALLESOSEHRUBERHANDNIMMTDASSNURSTARKEMORALISCHEGRUNDSATZEMICHDAVONABHALTENKONNENMITVORSATZAUFDIESTRASSEZUTRETENUNDDENLEUTENMITBEDACHTDIEHUTEVOMKOPFZUHAUENDANNISTESHOCHSTEZEITFURMICHSOBALDICHKANNAUFSEEZUKOMMEN',

      cipherText:
        'RWGKNOKENVKZVGMNUSKPAKBXUHBBJWXGNBZBRBTPQOAVGHXDPVPZAWZWVGZVEYXDAUXGQWFWRIMZYIGYNBEVARKZVNMZZWVCAWVCGGUZFCGYRFXNHBWNBRTXUHBXUABMVQARBZEORWGRRBBBUSKPZGXBRZGPARFDERXIJOLNRFBBRBMZVZWZEKXGGPXNRVXIQOLDFHLJZSBIROKOZWKYVSFDYNLPPVMUHJXMGFXDOSGPARWZAYKZVGEVHTBIFQARHBZUHPKDAUXIVAFZEKXIAWVCZSKFRRTNFWVCHAWZAANIQVXMHAZMVAFDTKXMQSBHZSKRRBGDAAXDASKNRSEZAOLNRFGDRGEDTSKIBJXHOSKCRFKNPVMDZAXMJSGIVQAHRFDZQOLNVQAQBFLVEUEVTSKIFHXCRBUGRWUZHBWERRXHYSBXUSGUHUADAHXMUSKOECMORRXMZWKWRUXBASMPARUZFCGYRFLDZAXMQOGIJSGIZSBIRGVCJOKURUTGYSLJFSAMHPXMUOGYAWFHGRTNFBNMFHTMXSFJEOEDFQAZTFNIQGTOMSFDPVWVICGVOVTGGSGFBBGZAABOICKNNHSVHTWDRGMMNGLZMIMMRHXIHBWYRBEZHHXIZWMWRRTXUHWDRVNORJHHXCIAMIAVHSGYNBGDFHXNUCVCFHXURWMAHFFDPVLJOOEYVQAFNBGVHTLZRNNFBAFZA',

      // key: NOTV
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Der kleine Prinz',

      language: ProblemLanguages.DE,

      plainText:
        'SOLEBTEICHALLEINOHNEJEMANDENMITDEMICHWIRKLICHHATTESPRECHENKONNENBISICHVORSECHSJAHRENEINEPANNEINDERWUSTESAHARAHATTEIRGENDETWASANMEINEMMOTORWARKAPUTTGEGANGENUNDWEILICHWEDEREINENMECHANIKERNOCHPASSAGIEREBEIMIRHATTEBEREITETEICHMICHDARAUFVORDIESCHWIERIGEREPARATURGANZALLEINVORZUNEHMENDABEIGINGESFURMICHUMLEBENUNDTODICHHATTETRINKWASSERFURKNAPPACHTTAGEAMERSTENABENDSCHLIEFICHIMSANDEINTAUSENDEMEILENENTFERNTVONBESIEDELTEMGEBIETICHWARVIELABGESCHIEDENERALSEINSCHIFFBRUCHIGERMITTENIMOZEANSOKONNTIHREUCHVORSTELLENWIEUBERRASCHTICHWARALSICHBEISONNENAUFGANGVONEINERLEISENLUSTIGENSTIMMEGEWECKTWURDE',

      cipherText:
        'EZXQMFQTOTLXXPUZZTZPVQXMZOQZXUFOQYTOTHUDVXUNTTLFFPEBCQOSQZVAZYQZMUETOTGADDQOSEVLTDPZQTZQAMZYQUYPQCIGDFQDMTLDMSMFEQUCSQYPQEIMDMZXQUYQYXAFZDILDWLBGEFSPSMYSQYGZOIQTXUNTIPPQCQUYQZXQOSMZTWQCZANTBLEELSUPDQMQUXUDSMFEQNPDQTFQEQUNTYTOTOMDLGRGADOUQDOTHUQCUSPDQAMDLFGCSMYLMWXQTZHZDLFZQSYQYPMMQURUZRQEQGDXUOSGYWQNPZGYPFZPUNTTLFFPFDTZWHMEDQDQGDVZMABMNTFEMSPMYPDEEQZLNQYPENTXTQRTOTTYELZPPUZEMGDQZOQYPUXPZQYFRPDZEHAYNQDUQOQXEQYRQNTQFTOTHMDGUQWMNRQENTUPPQYQDLXEPUZDOTTRRMDGNTURQDXUFEQZTYAKQMYEAVAZYFUSDQFOTGADDFQWXQYIUPGNPDDLEOSFUNTILDMWEUNTNPUEZZZPZMFRSLZSGAZPUZPDXPUEPZXFEFTSQYEFTYYPSQHQOVFIFDPP',

      // key: MLM
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Sherlock Holmes',

      language: ProblemLanguages.DE,

      plainText:
        'SHERLOCKHOLMESWARWIEVERWANDELTWENNERAUFEINESOLCHESPURSTIESSMENSCHENDIENURDENSTILLENDENKERUNDLOGIKERAUSDERBAKERSTREETKANNTENHTTENIHNNICHTERKANNTSEINGESICHTERRTETEUNDVERDUNKELTESICHSEINEBRAUENWARENZUZWEIHARTENSCHWARZENLINIENGEZOGENWHRENDSEINEAUGENDARUNTERMITEINEMSTHLERNENGLITZERNHERVORLEUCHTETENSEINGESICHTWARNACHUNTENGEBOGENDIESCHULTERNGEBEUGTDIELIPPENZUSAMMENGEPRESSTUNDDIEADERNTRATENWIEPEITSCHENSCHNREINSEINEMLANGENSEHNIGENHALSHERVORSEINENASENLCHERSCHIENENSICHMITEINERREINTIERISCHENLUSTANDERJAGDZUWEITENUNDSEINGEISTWARSOABSOLUTAUFDIESACHEKONZENTRIERTDIEERVORSICHHATTEDASSEINEFRAGEODERBEMERKUNGUNGEHRTBLIEBODERHCHSTENSEINSCHNELLESUNGEDULDIGESKNURRENALSANTWORTHERVORRIEFZGIGUNDLAUTLOSSCHLUGERDENWEGEINDERDURCHDIEWIESENUNDDAMITDURCHDENWALDZUMBOSCOMBEPOOLFHRTEESWARFEUCHTERSUMPFIGERBODENWIEBERALLINDIESERGEGENDUNDESGABVIELEFUSSSPURENSOWOHLAUFDEMWEGALSAUCHINDEMKURZENGRASDASIHNAUFBEIDENSEITENBEGRENZTEMANCHMALEILTEHOLMESWEITERMANCHMALBLIEBERSTEHENUNDEINMALMACHTEEREINENKLEINENUMWEGBERDIEWIESE',

      cipherText:
        'PHUTIOSMEOBOBSMCOWYGSEHYXNTGITMGKNUTXUVGFNUULLSJBSFWOSJKBSIOBNIEEEDFFEDWODUPPTYNIEDFBNAGOUDFIOWKHEHCRSTGOBQMBRIVOEUVHADPQEDJQTUPFHDPFCXVBRACKNJUBIDIBSYEETUTOTUVBUDFSEHFRNAGITUUFCXUBIDGYRQWBNMCOEDBRZMGFHQTQEDUZHMCOZUPIIDKBNWGWOWGKWXTBNTUBIDGXUWGKDQTRNJGOMYVBIDGJSJJIEHPBNWNFTPGONXGOVETIEKEETUVBNIGFNWGPISJQWQTKASJRNJGKGUDLGUPAIUUZHKNQEHPDERGRGJFFEBKMPUPWUICJMUPDEFTBSIVRNTFFEQFBRDVOAJGKWYGMEYVPCXGKSSJKRUKKSUKKECNXNWGKSUJKIWGKHQNPHUTSOHUBIDGKAIGKLSJBRIEEIUPBNIKZHCKQEYPBRHGFNJKBRYUZHUPIUIVXNTGOJQIAZKYBIJGKUDFPEYPDEYUQWQTPOQDPOBWQAKHAIUUXCXGHODBBNJTFEHVAIUGOVETPISJEAJVBDQUPEYPBFHCDEEFBRRGJEHMRNWWKGUJOTRNFERQAEHJZHIVBNIGFNIEENUNIEIWKGUFRLTKDEIMKUHTBNQNPADVTOHVEEHXLRHKBFPIFGKPALQWQLEUPCXNRGUTAEDYBGUKKDUTAUHEEDYGTIUUBNKPADQOFTTWOCXFBNMCIDPWJBEUZOCDBPEQIFXTQEUUTAHHBUSJQEHURMFHFGUTYOTGKWYGYEHCILYPAIUUBRWGDEDFRNTGPGQDSIUNBFKUPSFWOEDULWEJIAKHAECYBGQNPAKEEIDFBMAWOZUPDRQUAAIKENQWCBUKAEDUBIJGKBUIOEDBQECCKCXOXLUKITUJLLCGPWUKQEHOXNSJJABDIIUDBRIVBHUPRNTGFNCCIMQEETUGOEYPBNANBIDGKUCYBGRGODYGTIUUB',

      // key: XAQC
    },
  ],
  en: [
    {
      type: ProblemTypes.VIGENERE,

      title: 'Daddy Long Legs',

      language: ProblemLanguages.EN,

      plainText:
        'THEFIRSTWEDNESDAYINEVERYMONTHWASAPERFECTLYAWFULDAYADAYTOBEAWAITEDWITHDREADENDUREDWITHCOURAGEANDFORGOTTENWITHHASTEEVERYFLOORMUSTBESPOTLESSEVERYCHAIRDUSTLESSANDEVERYBEDWITHOUTAWRINKLENINETYSEVENSQUIRMINGLITTLEORPHANSMUSTBESCRUBBEDANDCOMBEDANDBUTTONEDINTOFRESHLYSTARCHEDGINGHAMSANDALLNINETYSEVENREMINDEDOFTHEIRMANNERSANDTOLDTOSAYYESSIRNOSIRWHENEVERATRUSTEESPOKE',

      cipherText:
        'DFODSPCRGCNLOQNYIGXCFCBWWMXRRUKQKNOPPCMRVWKUPSVBKWKBKWDMLCKUKGDCNUSRRBBCKBOLNSBCNUSRRAYSBYQCKLNDYPQMDROLGGDFRYCROCFCBWPJYMBKEQDZOQZMDJOQCCFCBWMFKGBBEQDJOQCYXBOTOPIZOBGGDFYSDYGPSLUJOLSLORIQOTOLCOEGBKSLQJSRDJOMBNRYXQWSCRLCCABSLZOBKLNAYKLCNYXBLSDRYLOBSLDMPPOQRJIQDYBARCNESLQFKKCYXBKJVLSLORIQOTOLBCWGXBOBYDDFOGBKKLXCBQKLNRYJNRYQKWICCQSPXMCGBURCXCFCBYDPEQDCOQZMUC',

      // key: KY
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Human Rights 1',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDTOALLTHERIGHTSANDFREEDOMSSETFORTHINTHISDECLARATIONWITHOUTDISTINCTIONOFANYKINDSUCHASRACECOLOURSEXLANGUAGERELIGIONPOLITICALOROTHEROPINIONNATIONALORSOCIALORIGINPROPERTYBIRTHOROTHERSTATUSFURTHERMORENODISTINCTIONSHALLBEMADEONTHEBASISOFTHEPOLITICALJURISDICTIONALORINTERNATIONALSTATUSOFTHECOUNTRYORTERRITORYTOWHICHAPERSONBELONGSWHETHERITBEINDEPENDENTTRUSTNONSELFGOVERNINGORUNDERANYOTHERLIMITATIONOFSOVEREIGNTY',

      cipherText:
        'OBXYIUGLSYXUDOMSOJMVKREARKKPQNMZKTWMBKXKYSLZOZYVBZAPXZAPCJXJVGKHDOHUGOMOYAMKSYMPXIMPYTHMKTRRSTWZEIAHCXTJOIHSYAKZODEHXMNHQKKLVOZPYTIVVOMPMGEVBUMOOXHWSTBVXTTASUGHVUKZYIBHVUKPQOGWBUILBZRISXMOYXHARKKZDGMBCLNYDNXYWUKLXUWPCZBUMZBVXYAHVRULWGWLYTMOOHTZSYHMDNXWYRBASITSTAKPCJBJDOHUKRHYSTMLBTTASUGHVYMHDALVPZALMUNUDXRVBZXYBOMVBEMVGNBJRGILBYHULKEVXMLDRKMOOXBALKBUNKILXJXUDZKBCZGVXYXSPMHCOXGPXMHYETWLBGGFYZALBRBTSZTASUGVPYHCOXXPQTMF',

      // key: KGTH
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Human Rights 2',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDINFULLEQUALITYTOAFAIRANDPUBLICHEARINGBYANINDEPENDENTANDIMPARTIALTRIBUNALINTHEDETERMINATIONOFHISRIGHTSANDOBLIGATIONSANDOFANYCRIMINALCHARGEAGAINSTHIM',

      cipherText:
        'HLHHBEQULIHDWYWBHTLDIKOBHGXQOYWOWEDVDYUQQTSKEBLSKUDHLDJRBQQYQTHFHDGUQJDDGYPFDHWYDBWHLRXDDBLDWXHTHJHHPYQQWYRDRVKYVHLWKJVQQTRROYJQWYRDVQQTRVDDBSUYPYQQOSKQUWHQJQLDVJKYP',

      // key: DQ
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Human Rights 3',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEHASTHERIGHTTOFREEDOMOFOPINIONANDEXPRESSIONTHISRIGHTINCLUDESFREEDOMTOHOLDOPINIONSWITHOUTINTERFERENCEANDTOSEEKRECEIVEANDIMPARTINFORMATIONANDIDEASTHROUGHANYMEDIAANDREGARDLESSOFFRONTIERS',

      cipherText:
        'TXTTNQCGWCHVWGGKVJIVDHGGTFDODHDRXPXQCCCFTZETTUHKDPIJXUGKVJIKCEAWSGHHGGTFDOIQWQAFDRXPXQCULKIJDWIKCVTTUGGGCETCCFIQHGTMGGRGXXTCCFXOECGVXPUQGOPVXQCCCFXFTCHVWTDWVJPPNOTFXCPPSTTIPTSNTUHQUHGQCVXGGU',

      // key: PC
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Moby Dick',

      language: ProblemLanguages.EN,

      plainText:
        'SOMEYEARSAGONEVERMINDHOWLONGPRECISELYHAVINGLITTLEORNOMONEYINMYPURSEANDNOTHINGPARTICULARTOINTERESTMEONSHOREITHOUGHTIWOULDSAILABOUTALITTLEANDSEETHEWATERYPARTOFTHEWORLDITISAWAYIHAVEOFDRIVINGOFFTHESPLEENANDREGULATINGTHECIRCULATIONWHENEVERIFINDMYSELFGROWINGGRIMABOUTTHEMOUTHWHENEVERITISADAMPDRIZZLYNOVEMBERINMYSOULWHENEVERIFINDMYSELFINVOLUNTARILYPAUSINGBEFORECOFFINWAREHOUSESANDBRINGINGUPTHEREAROFEVERYFUNERALIMEETANDESPECIALLYWHENEVERMYHYPOSGETSUCHANUPPERHANDOFMETHATITREQUIRESASTRONGMORALPRINCIPLETOPREVENTMEFROMDELIBERATELYSTEPPINGINTOTHESTREETANDMETHODICALLYKNOCKINGPEOPLESHATSOFFTHENIACCOUNTITHIGHTIMETOGETTOSEAASSOONASICAN',

      cipherText:
        'EISQSKMLYMAUZYBQLSUHJTICXITSJXQWOEYRKBGHCTSFOFNRQIXZISAHKKCTYSVGLYQUTPHUFBOZAVMLZUWAXUXFIOZNKDYYFGKAHYTIXQCZTIASBZUQUGFJEUOXUHAOZMFOFNRQUTPMKQNNQQGFYXKJGDNURNNQQUDFJUNOEUCMSOTUBQILPLOHCTSILRNNQMVXYKZUTPLKSORMNOZAZTYIULIGFGFCUZQNQHKHYXUZOZXSKMKXZMDICUHMSLOYUHAOZFBKYIAFBCTYTQPKDCZUMGPUSBXXUTFXSTAPKYVKDCTYSYAORIBKZYBQLORCTPGEEYRRCTHIRGHZMLOXSVMOYUHMNYLALKOILRCTIUXQBUGMKEUTPVXUHMUHMGJZTYXQUXAZKHYXKZAZYXMFOYYKFUTPYYBYIUURXSCTYTQPKDGETSVAMMQNYGWNMHABJKDBGZXURGKFBGFCZDYWGCXQMGENXAHMYIXMFVDCTOCVXYZAJXQPKZNSQZXAGJQFONYXMNKXSYFYVBCTSCTFIZTYYFLKQNGZXSQNNAXOOURXSQZIIWCTSJKAJRQMNMNYAZLFBKZCGOWUGHZUNNUANFCSQNUSYZFIYQUGEMUAHGECIMH',

      // key: MUG
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'The Little Prince',

      language: ProblemLanguages.EN,

      plainText:
        'SOILIVEDMYLIFEALONEWITHOUTANYONETHATICOULDREALLYTALKTOUNTILIHADANACCIDENTWITHMYPLANEINTHEDESERTOFSAHARASIXYEARSAGOSOMETHINGWASBROKENINMYENGINEANDASIHADWITHMENEITHERAMECHANICNORANYPASSENGERSISETMYSELFTOATTEMPTTHEDIFFICULTREPAIRSALLALONEITWASAQUESTIONOFLIFEORDEATHFORMEIHADSCARCELYENOUGHDRINKINGWATERTOLASTAWEEKTHEFIRSTNIGHTTHENIWENTTOSLEEPONTHESANDATHOUSANDMILESFROMANYHUMANHABITATIONIWASMOREISOLATEDTHANASHIPWRECKEDSAILORONARAFTINTHEMIDDLEOFTHEOCEANTHUSYOUCANIMAGINEMYAMAZEMENTATSUNRISEWHENIWASAWAKENEDBYANODDLITTLEVOICE',

      cipherText:
        'UEKBKLGTOONYHUCBQDGMKJJEWJCDAEPUVXCJKSQKNTTUCBNOVQNAVEWDVYNYJQFQPQESKTGDVMKJJCAFNQPUKDVXGTGIGHVEHICXCHCIKNAUCHUQIEUEOUVXKDIMCIDHQAGDKDOOGDIYPUCDFQUYJQFMKJJCGDGYVXGHCCGSJQPYEDQHCDAFCIUUPWGHUYUUVCAIGBHJQQVJGCRJVXGTKVHYEKNJTURQKHUQNBCBQDGYVMCICGWUUJKEPEHBKVGETTGQVXHETCGYJQFIEQTSGBAUPEWWJTTYPAKDIMCJGHVENQUJCMGUMJJUHYTIVDKWJJVXGDKMGDVJQINUGFQDVXGICDFQVXQKUQPTOYNUUVTEOQPOJKOQPXCRKJCJKEPYYQUCQHGYUENQVUFJJQPQUXKFYHGSMUFICYNETEPQTQHJKDVXGCKTFBGEHJJUQSGQPJJKUOQKEQPYOQIYPUOOCCCPGCGDVQVIWDTYUUYXGDKMCICMCAGDGTDOCDQTFBKJVBGLQYEU',

      // key: CQ
    },
    {
      type: ProblemTypes.VIGENERE,

      title: 'Sherlock Holmes',

      language: ProblemLanguages.EN,

      plainText:
        'SHERLOCKHOLMESWASTRANSFORMEDWHENHEWASHOTUPONSUCHASCENTASTHISMENWHOHADONLYKNOWNTHEQUIETTHINKERANDLOGICIANOFBAKERSTREETWOULDHAVEFAILEDTORECOGNISEHIMHISFACEFLUSHEDANDDARKENEDHISBROWSWEREDRAWNINTOTWOHARDBLACKLINESWHILEHISEYESSHONEOUTFROMBENEATHTHEMWITHASTEELYGLITTERHISFACEWASBENTDOWNWARDHISSHOULDERSBOWEDHISLIPSCOMPRESSEDANDTHEVEINSSTOODOUTLIKEWHIPCORDINHISLONGSINEWYNECKHISNOSTRILSSEEMEDTODILATEWITHAPURELYANIMALLUSTFORTHECHASEANDHISMINDWASSOABSOLUTELYCONCENTRATEDUPONTHEMATTERBEFOREHIMTHATAQUESTIONORREMARKFELLUNHEEDEDUPONHISEARSORATTHEMOSTONLYPROVOKEDAQUICKIMPATIENTSNARLINREPLYSWIFTLYANDSILENTLYHEMADEHISWAYALONGTHETRACKWHICHRANTHROUGHTHEMEADOWSANDSOBYWAYOFTHEWOODSTOTHEBOSCOMBEPOOLITWASDAMPMARSHYGROUNDASISALLTHATDISTRICTANDTHEREWEREMARKSOFMANYFEETBOTHUPONTHEPATHANDAMIDTHESHORTGRASSWHICHBOUNDEDITONEITHERSIDESOMETIMESHOLMESWOULDHURRYONSOMETIMESSTOPDEADANDONCEHEMADEQUITEALITTLEDETOURINTOTHEMEADOW',

      cipherText:
        'ABVATITTPICVMMNJANIJVMWXZGVMEBVWPYNJABFCCJFWAOTQIMTNVNRBBBZBUYEFPIYJLIEUGEEXEHKQMKLRMNKQQHBNZUEMTIXRKCRWWZSJSYIBBLVNBQFDTXYJDYWJQFVMBIINKIXWQMVQQGYRAZRLMZCDABVMIHUMILBNVYUQQMSAWQJFMLVMZUNWQHKXBQFQILUKTUTTTCENAQYRTYYRAYPNAMYXVYFDBZIXUVVWMUKQBBVVECKQIMKNMFPPTCKCMLYRAZRLMQRBJYECLINWEUIMPCJBPILULYIBJINNLBZBTCGBKIDYZYJBMXRWLNYNDYZWAMKXWXFDBFZTMQYRXWFALCEQQMCXVAJRVYNHVYTTPCJWWMKAQFJBMYDNLNFMQFRCMQZCPUGDZYCHIHZVIFCDANWXZNYNKBRBMUEMPCJVQHUFIMJXIVJXTOKNTSTXVWVWBLRCMXLYWHKQMGRCBYIKMZFAMBZVBBRCIKLNANZXVIIAMGRASZVUTOEQMYUNLOGXVBZBMUIBWLRCBBVVWMKXVFPYZIMXSYUJYOZLSCDYINZNVNJWILCRVLVYTSJFQZKUGUEMACCNVNCHPYDJLYYRAQRHIFFWONYNBLRLSQYRKBIJVNYAWOXQBBVVMUUXEMRWLMFKGQRHWZKQMQFXLMKXBBVKWMTXUVVYWICRBQRBLUDYUUIBPSXAWOEMIMZBIFCCPUKMQMKAQWKJVXKQMLVFMLVVILBBWZDJVSWNMNSXBBLYWHKQMJRCPUEMIGZMBBVBPIICOLRBAQYRKBSXCHUNLCKXVYZCPYIBQXVBWGVCQGVBPICVMMNXCFUQCLIHWHJXUYKRUYJBBIGMMUUJVXFWKYYNUUUNYOZCMUCRBNCNLYKXCLZWBIKQMGVJLIN',

      // key: IURJ
    },
  ],
};
