import { Problem, ProblemLanguages, ProblemTypes } from '../types';

export const tableProblems: { [id: string]: Problem[] } = {
  en: [
    {
      type: ProblemTypes.TABLE,

      title: 'Daddy Long Legs',

      language: ProblemLanguages.EN,

      plainText:
        'THEFIRSTWEDNESDAYINEVERYMONTHWASAPERFECTLYAWFULDAYADAYTOBEAWAITEDWITHDREADENDUREDWITHCOURAGEANDFORGOTTENWITHHASTEEVERYFLOORMUSTBESPOTLESSEVERYCHAIRDUSTLESSANDEVERYBEDWITHOUTAWRINKLENINETYSEVENSQUIRMINGLITTLEORPHANSMUSTBESCRUBBEDANDCOMBEDANDBUTTONEDINTOFRESHLYSTARCHEDGINGHAMSANDALLNINETYSEVENREMINDEDOFTHEIRMANNERSANDTOLDTOSAYYESSIRNOSIRWHENEVERATRUSTEESPOKE',

      cipherText:
        'TENTHDEHENVWFEEAISRSRDYASAMPTYOEWINRFUTEELODCDBWTAEILYATYAWHADADWAIRFYTEAWGODIETETATNHNEDCDNUOFWRUOIERRTDAGHHYTSAFBSSLEETOSVEOPEERORVMTYEULCRSEHASYUISBTRAEADNDWUDWRSEIITVTNLEHKEROLEVIONENRINGPNSLHEQIATUTNYITSSRLMEMEUSBBTTEEOBDDNEAAESNNDCDDIRCBNUOUTBMTOFAGLRRHLECANSHMIHESNLDAEYGNTSIDYTNASEDRNVEMDEDATNONORFNLETEDMHRTIESONIASAOVEYSEEYIRSERAPSWTOSHRKIEUERNSTNETM',

      // key: rows: 4, columns: 9
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Human Rights 1',

      language: ProblemLanguages.EN,

      plainText:
        'ALLHUMANBEINGSAREBORNFREEANDEQUALINDIGNITYANDRIGHTSTHEYAREENDOWEDWITHREASONANDCONSCIENCEANDSHOULDACTTOWARDSONEANOTHERINASPIRITOFBROTHERHOOD',

      cipherText:
        'AMIRNAUDLANEFNAILNGBRDLGHBSOEEINUEAREQNITRSADWEAYITROIANAGHEWTSDNHEEEHOCDTYNDRNONNDLTDAESCSDOSNRCEHAWOOIIAOCANTNENUTREHASTOHFMLRPOTOMKMIIFHOOHCZRBEDJNIOIRRCMBRA',

      // key: rows: 8, columns: 5
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Human Rights 2',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDTOALLTHERIGHTSANDFREEDOMSSETFORTHINTHISDECLARATIONWITHOUTDISTINCTIONOFANYKINDSUCHASRACECOLOURSEXLANGUAGERELIGIONPOLITICALOROTHEROPINIONNATIONALORSOCIALORIGINPROPERTYBIRTHOROTHERSTATUSFURTHERMORENODISTINCTIONSHALLBEMADEONTHEBASISOFTHEPOLITICALJURISDICTIONALORINTERNATIONALSTATUSOFTHECOUNTRYORTERRITORYTOWHICHAPERSONBELONGSWHETHERITBEINDEPENDENTTRUSTNONSELFGOVERNINGORUNDERANYOTHERLIMITATIONOFSOVEREIGNTY',

      cipherText:
        'EETLGFVILLHRESETTEREDHSEYNTEADOTORNONIAIDMSTSATSSHDTHTEIEIOITNCOUNFTLNTCOHAWDTRIRIIIOKHCEANIAOXGONSLLEFDROARASAUNENUCRGLYCESUIGIRPAOITOITROITNISNCHIOOPAEONCOLRNAILOONLALPYRTRORBOATROITTHIPRHUEGETESRIRHRFMNTOSUORTNETSEISMHONNHAEFOCADBTDTLEAHIILOSESOBNIPOLILRALJCONLIUTRASTRIITTIIONIACSNTOTADAENUSOROISOUTRCOFNEYHNTTRTABHRROPEEYIWELCOTHRONHIDSLGENETFSRDNNGWIETOOHTPTNVEBERSETENUERNNOMNRIDTIOENEHTFIGREASGOARTONRNLIVTUYIOEY',

      // key: rows: 6, columns: 7
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Human Rights 3',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDINFULLEQUALITYTOAFAIRANDPUBLICHEARINGBYANINDEPENDENTANDIMPARTIALTRIBUNALINTHEDETERMINATIONOFHISRIGHTSANDOBLIGATIONSANDOFANYCRIMINALCHARGEAGAINSTHIM',

      cipherText:
        'EOVNEERIYSELNETDIITNFQUULALLEITFYATIORAANLDIPCUHBEABRYIANNGINNDDEEPNETAPNADRITMIABLUTNRAILIDNETTHEERMIIONNAOTFHGIHSTRSIANIDGOABTLIODNOSFAANNYICNRAILMCHAAGRAGIENSZTAHXIJMQ',

      // key: rows: 2, columns: 5
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Human Rights 4',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEHASTHERIGHTTOFREEDOMOFOPINIONANDEXPRESSIONTHISRIGHTINCLUDESFREEDOMTOHOLDOPINIONSWITHOUTINTERFERENCEANDTOSEEKRECEIVEANDIMPARTINFORMATIONANDIDEASTHROUGHANYMEDIAANDREGARDLESSOFFRONTIERS',

      cipherText:
        'ENHTVEETEHRORAIFYSGROTHEEONPDPAROINEMNDSOIESFOXIORNSNICFTGLRHHUEITDESIEDOLITMDOHTONOOPSUHIWTONIINRNETEDKENTRRCOEFESCEAEEIIIAVMNTEPFIAAOONRRNDTMANSGEDTHDIHAIDRNAEOYAAUMNDDFIRLFEEERRGSOSASNSROTT',

      // key: rows: 4, columns: 6
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Alan Turing',

      language: ProblemLanguages.EN,

      plainText:
        'ALANTURINGHASANEXTENSIVELEGACYWITHSTATUESOFHIMANDMANYTHINGSNAMEDAFTERHIMINCLUDINGANANNUALAWARDFORCOMPUTERSCIENCEINNOVATIONS',

      cipherText:
        'AUHESLRAXIAISTVNNAEETGNNLEWTSMGIAOAATTFNCHUHDYSEIMAIAFINNMTMYGEEITSDRNHNAHCLGNWOUAUARDNARCIALDONNAFMPSCOOUCEVNTIIASEENTKRNNIQ',

      // key: rows: 5, columns: 5
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Martin Luther King Jr.',

      language: ProblemLanguages.EN,

      plainText:
        'ISAYTOYOUTODAYMYFRIENDSSOEVENTHOUGHWEFACETHEDIFFICULTIESOFTODAYANDTOMORROWISTILLHAVEADREAMITISADREAMDEEPLYROOTEDINTHEAMERICANDREAM',

      cipherText:
        'IOOYNEHSYDFDVOAOARSEUYUYISNGTTMEOTHWEIUSDDETFLOATFHFTFYOAEIITAMCDCEONORSHDIDDRTARTREOIVEIEEWLEASAPILAMAMLYEHRDHBRDEIRUVOIACEBKONMAAETTTENMAV',

      // key: rows: 7, columns: 5
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Computer Science',

      language: ProblemLanguages.EN,

      plainText:
        'COMPUTERSCIENCEINVOLVESTHESTUDYOFORTHEPRACTICEOFCOMPUTATIONAUTOMATIONANDINFORMATIONCOMPUTERSCIENCESPANSFROMTHEORETICALTOPRACTICALDISCIPLINES',

      cipherText:
        'CSNOCVMIOPELUNVTCEEESRITHFAEOCSRTTTIUHCDEEYPOORFCIAOOTMNIPAOUUNTTAAONTMDIITNOEFNROCSROCMMIAPETUNCREEOTSMIPTCAHANELSOTFROPLIRDNAIECSSTCXIIRCPFALW',

      // key: rows: 3, columns: 8
    },
  ],
  de: [
    {
      type: ProblemTypes.TABLE,

      title: 'Im tropischen Busch',

      language: ProblemLanguages.DE,

      plainText:
        'UNDURCHDRINGLICHERDSCHUNGELBEDECKTDIEWEITENEBENENDERFLUSSGEBIETEDESPANUCOUNDDESTAMESIZWEIBAHNLINIENNURDURCHZIEHENDIESENNEUNZIGTAUSENDQUADRATKILOMETERGROSSENTEILDERTIERRACALIENTEWOSICHANSIEDELUNGENBEFINDENHABENSIESICHDICHTUNDNGSTLICHANDIEWENIGENEISENBAHNSTATIONENGEDRAENGTEUROPAEERWOHNENHIERNURGANZVEREINZELTUNDWIEVERLORENDIEERMUEDENDEGLEICHFOERMIGKEITDESDSCHUNGELSWIRDVONEINIGENSICHLANGHINSTRECKENDENHOEHENZUEGENUNTERBROCHENDIEMITTROPISCHEMURBUSCHBEWACHSENSINDDEREBENSOUNDURCHDRINGLICHISTWIEDERDSCHUNGELUNDINDESSENTIEFENWOIMMERDAEMMERUNGHERRSCHTALLEMYSTERIENUNDGRAUENDERWELTZULAUERNSCHEINEN',

      cipherText:
        'UNCENGHCDLUKUINTRCGDCHEIHELEDRBWRDEEISDITEIUERECNFTOELEUBUDNESEDNSSDEGPENEASDBNTAAUHMHREENDNSLUDIIRIZNCEWIHSEEZEININBNENEEKRUNIONDLSZQOSIUMEGAENTDTTAREEUARISTGLDAILELCURIHNTEAGINNEETSNREIBRWEEAODFCSEINITCDEUHESNANIDNHCNDAHGIBDSEEITWNCLESHINIAETGHNEENGUNSERETDOIARPSTAAEIEENONEBNGRWNEWOUIIHRNENGZVEAEENNLRHZTLIVUOEENRRRDENEFTDNODIDEEEERSEGMDRLISMEGCUIKHECEUDHINGNCTEEHRLILESNACWINKIGGEREHNDNIDVSNEOISNHECTONHREUEOHNNPETDINEISZRECUBMHERIEGOTMUADNRCEDBHRUUSERSEBCCNEHHSNDBISRENOIWDUNGEGSLDESIELECRUNHDNTISDISCIETHNFWUDEINENWMRYOMSSIECTMRHEMUTRENAIRGLEDHLNAEEUERMNDWRTGENHRLSDATCGUZHOEUEJNLINDANREUEZRENH',

      // key: rows: 4, columns: 10
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Menschenrechte 1',

      language: ProblemLanguages.DE,

      plainText:
        'ALLEMENSCHENSINDFREIUNDGLEICHANWUERDEUNDRECHTENGEBORENSIESINDMITVERNUNFTUNDGEWISSENBEGABTUNDSOLLENEINANDERIMGEISTDERBRUEDERLICHKEITBEGEGNEN',

      cipherText:
        'ASLCLHEEMNESNINNDDFGRLEEIIUCHDAENUWNUDERRECBHOTREENNGSEIETSVIENRDNMUINFWTIUSNSDEGNEBEDGSAOBLTLUENNERIINMAGNEDIESTEDDEERRBLRIUCHGKEEGINTEBNEV',

      // key: rows: 2, columns: 7
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Menschenrechte 2',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFDIEINDIESERERKLAERUNGVERKUENDETENRECHTEUNDFREIHEITENOHNEIRGENDEINENUNTERSCHIEDETWANACHRASSEHAUTFARBEGESCHLECHTSPRACHERELIGIONPOLITISCHERODERSONSTIGERUEBERZEUGUNGNATIONALERODERSOZIALERHERKUNFTVERMOEGENGEBURTODERSONSTIGEMSTANDDESWEITERENDARFKEINUNTERSCHIEDGEMACHTWERDENAUFGRUNDDERPOLITISCHENRECHTLICHENODERINTERNATIONALENSTELLUNGDESLANDESODERGEBIETSDEMEINEPERSONANGEHOERTGLEICHGUELTIGOBDIESESUNABHAENGIGISTUNTERTREUHANDSCHAFTSTEHTKEINESELBSTREGIERUNGBESITZTODERSONSTINSEINERSOUVERAENITAETEINGESCHRAENKTIST',

      cipherText:
        'JHSEAPDTREAURNCHIIAEEUISFNEDDREEVRREKURLNKAGUEEHNNTDREEEUTCNDHNFEORIHETNIEEIDNREUGINENTNEEREASDNCEAHTCIWHRHAAARSUBSTEEFGEEPSCRCHAHTCLSHEGORILEOILNTIPISOOCDNHESERTRSIGBUEEGRRUUZNEEGNNOAADTLEIERORSOEKZRUIHNAEFLRTVEEEGBREUMNROGTOOGDNEESMRTSSITASENWRDEEDINETDAIERNRFUSKNCETHIMWEAEDCRGHDETENREAURUNPFDOGDLIHCTEHINTSRLCEICDTHEEERRNINONATLEIELONLNSUATNGAODNDEDESERLSGESIBDNIEEEMPTEERNESGROETNHGAOLEUGIEOCLBHTDGIIENESANEBGSHIUAGITESEUTRHUTANRNDFHSTTCSKHTEAEINBGESISTEERRLEUNIDGTEBZRETSSOONSRSESTIOINUNEVEIERTIAANEEGNTESESCNTHKARTVAIL',

      // key: rows: 3, columns: 5
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Menschenrechte 3',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFEINENWIRKSAMENRECHTSBEHELFBEIDENZUSTANDIGENINNERSTAATLICHENGERICHTENGEGENHANDLUNGENDURCHDIESEINEIHMNACHDERVERFASSUNGODERNACHDEMGESETZZUSTEHENDENGRUNDRECHTEVERLETZTWERDEN',

      cipherText:
        'JHSHIIEAPANRDTRUEKEAUFNSRNCEWAMCEBNAEHHEZNNTEIUDRSLDSIEBFETGEEAHRENRTEINISLNCGNTIGHENACETGEDECSINLNHEHHUDDIMANUINNNGREEACVSOAMHESDCGDRUEHEEFNRDSRAGNEETTDUCEZEENHRZHNDTLUEGREESNREVTZDDMEDTEQZDAWNGWAHEGRJKBRSWZBE',

      // key: rows: 6, columns: 5
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Menschenrechte 4',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATDASRECHTAUFMEINUNGSFREIHEITUNDFREIEMEINUNGSAUSSERUNGDIESESRECHTSCHLIESSTDIEFREIHEITEINMEINUNGENUNGEHINDERTANZUHANGENSOWIEUBERMEDIENJEDERARTUNDOHNERUCKSICHTAUFGRENZENINFORMATIONENUNDGEDANKENGUTZUSUCHENZUEMPFANGENUNDZUVERBREITEN',

      cipherText:
        'JAEFNETCMGDDHESEATIFRSANRHRUUEINENSHDMGEEFESRIRIAUTENUNUIUSGDRCSRIEHTEECLDISHIIHETEEESSSFITINIAENUNNIUNDZNNGEUMGERHEEHTANWRNAGIMJREEEETNUDDUSBIENOEERDOCTENHKANFNSUZOEIFERRCGNMUHRIATUAUCINNTHODKZENGEUNEENSZNDGUUEGZRBMEUEQPNVIZFUETRANRECNDBNB',

      // key: rows: 5, columns: 6
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Alan Turing',

      language: ProblemLanguages.DE,

      plainText:
        'ALANTURINGHATEINUMFANGREICHESVERMCHTNISMITSTATUENVONIHMUNDVIELENDINGENDIENACHIHMBENANNTSINDEINSCHLIESSLICHEINERJHRLICHENAUSZEICHNUNGFRINNOVATIONENINDERINFORMATIK',

      cipherText:
        'ANUIMLGMCCAHFHHNAAETTTNSNUEGVIRIRESINERMINNDETVDINSOVNATNIGCAIEEHTHLNIUMEDHEUNIMBIHCHENLHRNDIELAEEIINISNCNNSEHTSLRESCIJNANNENUUONFSNVIOZGANREFTDMIRIEACIORTHNNIIKIHRTXUZPEVBIIDKJPSACXNDWXDYSERTEAALDRSF',

      // key: rows: 5, columns: 8
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Martin Luther King Jr.',

      language: ProblemLanguages.DE,

      plainText:
        'ICHSAGEIHNENHEUTEMEINEFREUNDEAUCHWENNWIRMITDENSCHWIERIGKEITENVONHEUTEUNDMORGENKONFRONTIERTSINDSOHABEICHDOCHEINENTRAUMESISTEINTRAUMDERTIEFMITDEMAMERIKANISCHENTRAUMVERWURZELTIST',

      cipherText:
        'IEHECIEIHHUNSNTEAEEFGNMREUNTUCWDNHIEDWRNEEMSANICHGNUWKVTIEOEEINURTHNIEEDMKNSOOTIRNINGFEDERRSNOTOHHIAADNUBOEMECNEIHTSCERISRRITATTEUIDIMEENDFMTEMAMNNVEITERSRRICAWKHUUAEMRZTXQEPNRLYGGTFGOINSDSDVU',

      // key: rows: 4, columns: 6
    },
    {
      type: ProblemTypes.TABLE,

      title: 'Computer Science',

      language: ProblemLanguages.DE,

      plainText:
        'BEIDERINFORMATIKHANDELTESSICHUMDIEWISSENSCHAFTVONDERSYSTEMATISCHENDARSTELLUNGSPEICHERUNGVERARBEITUNGUNDBERTRAGUNGVONINFORMATIONENWOBEIBESONDERSDIEAUTOMATISCHEVERARBEITUNGMITDIGITALRECHNERNBETRACHTETWIRD',

      cipherText:
        'BNIEFKIOHDRAEMNRADITELHITUSEMSSDESINIESCWCHDEAEMFRATSTVYIOSSNTCHTSEEPNLEDLIAUCRNHSGERANURGNBUGENVIDETBRUERGOTVRROMANAGITUNINFONIEEBRNESWSDOOIBNEEDAUSATCROHBMEEAVITETIRUNGCGIHMTNIAETLRDRNIEBEEPTTNRWEAIDCRGHDXTPM',

      // key: rows: 3, columns: 7
    },
  ],
};
