import { Problem, ProblemLanguages, ProblemTypes } from '../types';

export const substitutionProblems: { [id: string]: Problem[] } = {
  en: [
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Daddy Long Legs',

      language: ProblemLanguages.EN,

      plainText:
        'THEFIRSTWEDNESDAYINEVERYMONTHWASAPERFECTLYAWFULDAYADAYTOBEAWAITEDWITHDREADENDUREDWITHCOURAGEANDFORGOTTENWITHHASTEEVERYFLOORMUSTBESPOTLESSEVERYCHAIRDUSTLESSANDEVERYBEDWITHOUTAWRINKLENINETYSEVENSQUIRMINGLITTLEORPHANSMUSTBESCRUBBEDANDCOMBEDANDBUTTONEDINTOFRESHLYSTARCHEDGINGHAMSANDALLNINETYSEVENREMINDEDOFTHEIRMANNERSANDTOLDTOSAYYESSIRNOSIRWHENEVERATRUSTEESPOKE',

      cipherText:
        'DWUVQFEDIUOXUEOMJQXUBUFJYLXDWIMEMAUFVUPDZJMIVCZOMJMOMJDLNUMIMQDUOIQDWOFUMOUXOCFUOIQDWPLCFMTUMXOVLFTLDDUXIQDWWMEDUUBUFJVZLLFYCEDNUEALDZUEEUBUFJPWMQFOCEDZUEEMXOUBUFJNUOIQDWLCDMIFQXRZUXQXUDJEUBUXEGCQFYQXTZQDDZULFAWMXEYCEDNUEPFCNNUOMXOPLYNUOMXONCDDLXUOQXDLVFUEWZJEDMFPWUOTQXTWMYEMXOMZZXQXUDJEUBUXFUYQXOUOLVDWUQFYMXXUFEMXODLZODLEMJJUEEQFXLEQFIWUXUBUFMDFCEDUUEALRU',

      // key: mnpouvtwqsrzyxlagfedcbihjk
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Human Rights 1',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDTOALLTHERIGHTSANDFREEDOMSSETFORTHINTHISDECLARATIONWITHOUTDISTINCTIONOFANYKINDSUCHASRACECOLOURSEXLANGUAGERELIGIONPOLITICALOROTHEROPINIONNATIONALORSOCIALORIGINPROPERTYBIRTHOROTHERSTATUSFURTHERMORENODISTINCTIONSHALLBEMADEONTHEBASISOFTHEPOLITICALJURISDICTIONALORINTERNATIONALSTATUSOFTHECOUNTRYORTERRITORYTOWHICHAPERSONBELONGSWHETHERITBEINDEPENDENTTRUSTNONSELFGOVERNINGORUNDERANYOTHERLIMITATIONOFSOVEREIGNTY',

      cipherText:
        'SASDNJKSOESKFOFZSWFJVZZFPSDOQPFEVKWRDSSWJLEESFRJDFPOKFPOEWSTZVDVFOJKBOFPJGFWOEFOKTFOJKJRVKNYOKWEGTPVEDVTSTJZJGDESCZVKQGVQSDSZOQOJKHJZOFOTVZJDJFPSDJHOKOJKKVFOJKVZJDEJTOVZJDOQOKHDJHSDFNUODFPJDJFPSDEFVFGERGDFPSDLJDSKJWOEFOKTFOJKEPVZZUSLVWSJKFPSUVEOEJRFPSHJZOFOTVZXGDOEWOTFOJKVZJDOKFSDKVFOJKVZEFVFGEJRFPSTJGKFDNJDFSDDOFJDNFJBPOTPVHSDEJKUSZJKQEBPSFPSDOFUSOKWSHSKWSKFFDGEFKJKESZRQJASDKOKQJDGKWSDVKNJFPSDZOLOFVFOJKJREJASDSOQKFN',

      // key: vutwsrqpoxyzlkjhidefgabcnm
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Human Rights 2',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEISENTITLEDINFULLEQUALITYTOAFAIRANDPUBLICHEARINGBYANINDEPENDENTANDIMPARTIALTRIBUNALINTHEDETERMINATIONOFHISRIGHTSANDOBLIGATIONSANDOFANYCRIMINALCHARGEAGAINSTHIM',

      cipherText:
        'WHWDKENWSAWNBSBQWVSNXCQQWFCZQSBKBEZXZSDZNVGCRQSYUWZDSNTRKZNSNVWGWNVWNBZNVSMGZDBSZQBDSRCNZQSNBUWVWBWDMSNZBSENEXUSADSTUBAZNVERQSTZBSENAZNVEXZNKYDSMSNZQYUZDTWZTZSNABUSM',

      // key: zryvwxtuspoqmnegfdabchjikl
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Human Rights 3',

      language: ProblemLanguages.EN,

      plainText:
        'EVERYONEHASTHERIGHTTOFREEDOMOFOPINIONANDEXPRESSIONTHISRIGHTINCLUDESFREEDOMTOHOLDOPINIONSWITHOUTINTERFERENCEANDTOSEEKRECEIVEANDIMPARTINFORMATIONANDIDEASTHROUGHANYMEDIAANDREGARDLESSOFFRONTIERS',

      cipherText:
        'EYENXRSEIBMLIENHGILLRFNEECRPRFRTHSHRSBSCEVTNEMMHRSLIHMNHGILHSDQZCEMFNEECRPLRIRQCRTHSHRSMUHLIRZLHSLENFENESDEBSCLRMEEJNEDEHYEBSCHPTBNLHSFRNPBLHRSBSCHCEBMLINRZGIBSXPECHBBSCNEGBNCQEMMRFFNRSLHENM',

      // key: badcefgihkjqpsrtonmlzyuvxw
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Moby Dick',

      language: ProblemLanguages.EN,

      plainText:
        'SOMEYEARSAGONEVERMINDHOWLONGPRECISELYHAVINGLITTLEORNOMONEYINMYPURSEANDNOTHINGPARTICULARTOINTERESTMEONSHOREITHOUGHTIWOULDSAILABOUTALITTLEANDSEETHEWATERYPARTOFTHEWORLDITISAWAYIHAVEOFDRIVINGOFFTHESPLEENANDREGULATINGTHECIRCULATIONWHENEVERIFINDMYSELFGROWINGGRIMABOUTTHEMOUTHWHENEVERITISADAMPDRIZZLYNOVEMBERINMYSOULWHENEVERIFINDMYSELFINVOLUNTARILYPAUSINGBEFORECOFFINWAREHOUSESANDBRINGINGUPTHEREAROFEVERYFUNERALIMEETANDESPECIALLYWHENEVERMYHYPOSGETSUCHANUPPERHANDOFMETHATITREQUIRESASTRONGMORALPRINCIPLETOPREVENTMEFROMDELIBERATELYSTEPPINGINTOTHESTREETANDMETHODICALLYKNOCKINGPEOPLESHATSOFFTHENIACCOUNTITHIGHTIMETOGETTOSEAASSOONASICAN',

      cipherText:
        'MUZEKEBOMBIUYEREOZHYDGUPWUYIVOEAHMEWKGBRHYIWHTTWEUOYUZUYEKHYZKVSOMEBYDYUTGHYIVBOTHASWBOTUHYTEOEMTZEUYMGUOEHTGUSIGTHPUSWDMBHWBCUSTBWHTTWEBYDMEETGEPBTEOKVBOTUFTGEPUOWDHTHMBPBKHGBREUFDOHRHYIUFFTGEMVWEEYBYDOEISWBTHYITGEAHOASWBTHUYPGEYEREOHFHYDZKMEWFIOUPHYIIOHZBCUSTTGEZUSTGPGEYEREOHTHMBDBZVDOHLLWKYUREZCEOHYZKMUSWPGEYEREOHFHYDZKMEWFHYRUWSYTBOHWKVBSMHYICEFUOEAUFFHYPBOEGUSMEMBYDCOHYIHYISVTGEOEBOUFEREOKFSYEOBWHZEETBYDEMVEAHBWWKPGEYEREOZKGKVUMIETMSAGBYSVVEOGBYDUFZETGBTHTOENSHOEMBMTOUYIZUOBWVOHYAHVWETUVOEREYTZEFOUZDEWHCEOBTEWKMTEVVHYIHYTUTGEMTOEETBYDZETGUDHABWWKXYUAXHYIVEUVWEMGBTMUFFTGEYHBAAUSYTHTGHIGTHZETUIETTUMEBBMMUUYBMHABY',

      // key: bcadefighjxwzyuvnomtsrpqkl
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'The Little Prince',

      language: ProblemLanguages.EN,

      plainText:
        'SOILIVEDMYLIFEALONEWITHOUTANYONETHATICOULDREALLYTALKTOUNTILIHADANACCIDENTWITHMYPLANEINTHEDESERTOFSAHARASIXYEARSAGOSOMETHINGWASBROKENINMYENGINEANDASIHADWITHMENEITHERAMECHANICNORANYPASSENGERSISETMYSELFTOATTEMPTTHEDIFFICULTREPAIRSALLALONEITWASAQUESTIONOFLIFEORDEATHFORMEIHADSCARCELYENOUGHDRINKINGWATERTOLASTAWEEKTHEFIRSTNIGHTTHENIWENTTOSLEEPONTHESANDATHOUSANDMILESFROMANYHUMANHABITATIONIWASMOREISOLATEDTHANASHIPWRECKEDSAILORONARAFTINTHEMIDDLEOFTHEOCEANTHUSYOUCANIMAGINEMYAMAZEMENTATSUNRISEWHENIWASAWAKENEDBYANODDLITTLEVOICE',

      cipherText:
        'IVRYROEDXMYRHECYVUEKRJFVPJCUMVUEJFCJRAVPYDWECYYMJCYZJVPUJRYRFCDCUCAARDEUJKRJFXMTYCUERUJFEDEIEWJVHICFCWCIRLMECWICGVIVXEJFRUGKCIBWVZEURUXMEUGRUECUDCIRFCDKRJFXEUERJFEWCXEAFCURAUVWCUMTCIIEUGEWIRIEJXMIEYHJVCJJEXTJJFEDRHHRAPYJWETCRWICYYCYVUERJKCICSPEIJRVUVHYRHEVWDECJFHVWXERFCDIACWAEYMEUVPGFDWRUZRUGKCJEWJVYCIJCKEEZJFEHRWIJURGFJJFEURKEUJJVIYEETVUJFEICUDCJFVPICUDXRYEIHWVXCUMFPXCUFCBRJCJRVURKCIXVWERIVYCJEDJFCUCIFRTKWEAZEDICRYVWVUCWCHJRUJFEXRDDYEVHJFEVAECUJFPIMVPACURXCGRUEXMCXCNEXEUJCJIPUWRIEKFEURKCICKCZEUEDBMCUVDDYRJJYEOVRAE',

      // key: cbadehgfrqzyxuvtswijpoklmn
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Sherlock Holmes',

      language: ProblemLanguages.EN,

      plainText:
        'SHERLOCKHOLMESWASTRANSFORMEDWHENHEWASHOTUPONSUCHASCENTASTHISMENWHOHADONLYKNOWNTHEQUIETTHINKERANDLOGICIANOFBAKERSTREETWOULDHAVEFAILEDTORECOGNISEHIMHISFACEFLUSHEDANDDARKENEDHISBROWSWEREDRAWNINTOTWOHARDBLACKLINESWHILEHISEYESSHONEOUTFROMBENEATHTHEMWITHASTEELYGLITTERHISFACEWASBENTDOWNWARDHISSHOULDERSBOWEDHISLIPSCOMPRESSEDANDTHEVEINSSTOODOUTLIKEWHIPCORDINHISLONGSINEWYNECKHISNOSTRILSSEEMEDTODILATEWITHAPURELYANIMALLUSTFORTHECHASEANDHISMINDWASSOABSOLUTELYCONCENTRATEDUPONTHEMATTERBEFOREHIMTHATAQUESTIONORREMARKFELLUNHEEDEDUPONHISEARSORATTHEMOSTONLYPROVOKEDAQUICKIMPATIENTSNARLINREPLYSWIFTLYANDSILENTLYHEMADEHISWAYALONGTHETRACKWHICHRANTHROUGHTHEMEADOWSANDSOBYWAYOFTHEWOODSTOTHEBOSCOMBEPOOLITWASDAMPMARSHYGROUNDASISALLTHATDISTRICTANDTHEREWEREMARKSOFMANYFEETBOTHUPONTHEPATHANDAMIDTHESHORTGRASSWHICHBOUNDEDITONEITHERSIDESOMETIMESHOLMESWOULDHURRYONSOMETIMESSTOPDEADANDONCEHEMADEQUITEALITTLEDETOURINTOTHEMEADOW',

      cipherText:
        'VGEUJQCKGQJIEVZBVOUBRVHQUIEDZGERGEZBVGQOPTQRVPCGBVCEROBVOGMVIERZGQGBDQRJWKRQZROGESPMEOOGMRKEUBRDJQFMCMBRQHABKEUVOUEEOZQPJDGBNEHBMJEDOQUECQFRMVEGMIGMVHBCEHJPVGEDBRDDBUKEREDGMVAUQZVZEUEDUBZRMROQOZQGBUDAJBCKJMREVZGMJEGMVEWEVVGQREQPOHUQIAEREBOGOGEIZMOGBVOEEJWFJMOOEUGMVHBCEZBVAERODQZRZBUDGMVVGQPJDEUVAQZEDGMVJMTVCQITUEVVEDBRDOGENEMRVVOQQDQPOJMKEZGMTCQUDMRGMVJQRFVMREZWRECKGMVRQVOUMJVVEEIEDOQDMJBOEZMOGBTPUEJWBRMIBJJPVOHQUOGECGBVEBRDGMVIMRDZBVVQBAVQJPOEJWCQRCEROUBOEDPTQROGEIBOOEUAEHQUEGMIOGBOBSPEVOMQRQUUEIBUKHEJJPRGEEDEDPTQRGMVEBUVQUBOOGEIQVOQRJWTUQNQKEDBSPMCKMITBOMEROVRBUJMRUETJWVZMHOJWBRDVMJEROJWGEIBDEGMVZBWBJQRFOGEOUBCKZGMCGUBROGUQPFGOGEIEBDQZVBRDVQAWZBWQHOGEZQQDVOQOGEAQVCQIAETQQJMOZBVDBITIBUVGWFUQPRDBVMVBJJOGBODMVOUMCOBRDOGEUEZEUEIBUKVQHIBRWHEEOAQOGPTQROGETBOGBRDBIMDOGEVGQUOFUBVVZGMCGAQPRDEDMOQREMOGEUVMDEVQIEOMIEVGQJIEVZQPJDGPUUWQRVQIEOMIEVVOQTDEBDBRDQRCEGEIBDESPMOEBJMOOJEDEOQPUMROQOGEIEBDQZ',

      // key: bacdehfgmlkjirqtsuvopnzywx
    },
  ],
  de: [
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Im tropischen Busch',

      language: ProblemLanguages.DE,

      plainText:
        'UNDURCHDRINGLICHERDSCHUNGELBEDECKTDIEWEITENEBENENDERFLUSSGEBIETEDESPANUCOUNDDESTAMESIZWEIBAHNLINIENNURDURCHZIEHENDIESENNEUNZIGTAUSENDQUADRATKILOMETERGROSSENTEILDERTIERRACALIENTEWOSICHANSIEDELUNGENBEFINDENHABENSIESICHDICHTUNDNGSTLICHANDIEWENIGENEISENBAHNSTATIONENGEDRAENGTEUROPAEERWOHNENHIERNURGANZVEREINZELTUNDWIEVERLORENDIEERMUEDENDEGLEICHFOERMIGKEITDESDSCHUNGELSWIRDVONEINIGENSICHLANGHINSTRECKENDENHOEHENZUEGENUNTERBROCHENDIEMITTROPISCHEMURBUSCHBEWACHSENSINDDEREBENSOUNDURCHDRINGLICHISTWIEDERDSCHUNGELUNDINDESSENTIEFENWOIMMERDAEMMERUNGHERRSCHTALLEMYSTERIENUNDGRAUENDERWELTZULAUERNSCHEINEN',

      cipherText:
        'YNBYSDHBSINGLIDHCSBQDHYNGCLECBCDJRBICVCIRCNCECNCNBCSFLYQQGCEICRCBCQPANYDOYNBBCQRAMCQIZVCIEAHNLINICNNYSBYSDHZICHCNBICQCNNCYNZIGRAYQCNBTYABSARJILOMCRCSGSOQQCNRCILBCSRICSSADALICNRCVOQIDHANQICBCLYNGCNECFINBCNHAECNQICQIDHBIDHRYNBNGQRLIDHANBICVCNIGCNCIQCNEAHNQRARIONCNGCBSACNGRCYSOPACCSVOHNCNHICSNYSGANZXCSCINZCLRYNBVICXCSLOSCNBICCSMYCBCNBCGLCIDHFOCSMIGJCIRBCQBQDHYNGCLQVISBXONCINIGCNQIDHLANGHINQRSCDJCNBCNHOCHCNZYCGCNYNRCSESODHCNBICMIRRSOPIQDHCMYSEYQDHECVADHQCNQINBBCSCECNQOYNBYSDHBSINGLIDHIQRVICBCSBQDHYNGCLYNBINBCQQCNRICFCNVOIMMCSBACMMCSYNGHCSSQDHRALLCMUQRCSICNYNBGSAYCNBCSVCLRZYLAYCSNQDHCINCN',

      // key: aedbcfghikjlmnoptsqryxvwuz
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Menschenrechte 1',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFDIEINDIESERERKLAERUNGVERKUENDETENRECHTEUNDFREIHEITENOHNEIRGENDEINENUNTERSCHIEDETWANACHRASSEHAUTFARBEGESCHLECHTSPRACHERELIGIONPOLITISCHERODERSONSTIGERUEBERZEUGUNGNATIONALERODERSOZIALERHERKUNFTVERMOEGENGEBURTODERSONSTIGEMSTANDDESWEITERENDARFKEINUNTERSCHIEDGEMACHTWERDENAUFGRUNDDERPOLITISCHENRECHTLICHENODERINTERNATIONALENSTELLUNGDESLANDESODERGEBIETSDEMEINEPERSONANGEHOERTGLEICHGUELTIGOBDIESESUNABHAENGIGISTUNTERTREUHANDSCHAFTSTEHTKEINESELBSTREGIERUNGBESITZTODERSONSTINSEINERSOUVERAENITAETEINGESCHRAENKTIST',

      cipherText:
        'QXWXLSUEUOKNLDVSUDYWRXROWRXKXLXLBAUXLDOZFXLBDXOWXEXOLXVSEXDOWYLXRSXREXOPSOXRLZXOWXROXODOEXLKVSRXWXEGUOUVSLUKKXSUDEYULTXZXKVSAXVSEKNLUVSXLXARZRPONPARERKVSXLPWXLKPOKERZXLDXTXLIXDZDOZOUERPOUAXLPWXLKPIRUAXLSXLBDOYEFXLCPXZXOZXTDLEPWXLKPOKERZXCKEUOWWXKGXREXLXOWULYBXRODOEXLKVSRXWZXCUVSEGXLWXOUDYZLDOWWXLNPARERKVSXOLXVSEARVSXOPWXLROEXLOUERPOUAXOKEXAADOZWXKAUOWXKPWXLZXTRXEKWXCXROXNXLKPOUOZXSPXLEZAXRVSZDXAERZPTWRXKXKDOUTSUXOZRZRKEDOEXLELXDSUOWKVSUYEKEXSEBXROXKXATKELXZRXLDOZTXKREIEPWXLKPOKEROKXROXLKPDFXLUXOREUXEXROZXKVSLUXOBERKE',

      // key: utvwxyzsrqbacopnmlkedfghji
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Menschenrechte 2',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATANSPRUCHAUFEINENWIRKSAMENRECHTSBEHELFBEIDENZUSTANDIGENINNERSTAATLICHENGERICHTENGEGENHANDLUNGENDURCHDIESEINEIHMNACHDERVERFASSUNGODERNACHDEMGESETZZUSTEHENDENGRUNDRECHTEVERLETZTWERDEN',

      cipherText:
        'RZXZBSVEVOFABDYSVDPZTOZOHTBQFVMZOBZYSEFWZSZLPWZTXZOJDFEVOXTUZOTOOZBFEVVELTYSZOUZBTYSEZOUZUZOSVOXLDOUZOXDBYSXTZFZTOZTSMOVYSXZBGZBPVFFDOUNXZBOVYSXZMUZFZEJJDFEZSZOXZOUBDOXBZYSEZGZBLZEJEHZBXZO',

      // key: vwyxzpustrqlmonacbfedghkij
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Menschenrechte 3',

      language: ProblemLanguages.DE,

      plainText:
        'JEDERHATDASRECHTAUFMEINUNGSFREIHEITUNDFREIEMEINUNGSAUSSERUNGDIESESRECHTSCHLIESSTDIEFREIHEITEINMEINUNGENUNGEHINDERTANZUHANGENSOWIEUBERMEDIENJEDERARTUNDOHNERUCKSICHTAUFGRENZENINFORMATIONENUNDGEDANKENGUTZUSUCHENZUEMPFANGENUNDZUVERBREITEN',

      cipherText:
        'IFGFLKCVGCXLFDKVCWAQFHRWRBXALFHKFHVWRGALFHFQFHRWRBXCWXXFLWRBGHFXFXLFDKVXDKPHFXXVGHFALFHKFHVFHRQFHRWRBFRWRBFKHRGFLVCRZWKCRBFRXMUHFWEFLQFGHFRIFGFLCLVWRGMKRFLWDJXHDKVCWABLFRZFRHRAMLQCVHMRFRWRGBFGCRJFRBWVZWXWDKFRZWFQNACRBFRWRGZWTFLELFHVFR',

      // key: cedgfabkhijpqrmnolxvwtuysz
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Moby Dick',

      language: ProblemLanguages.DE,

      plainText:
        'EINPAARJAHREISTSHERUNWICHTIGWIELANGGENAUDAHATTEICHWENIGBISGARKEINGELDIMBEUTELUNDANLANDREIZTEMICHNICHTSBESONDERESUNDSODACHTICHMIRICHWOLLTEINWENIGHERUMSEGELNUNDMIRDENWASSERIGENTEILDERWELTBESEHENDASISTSOMEINEARTMIRDIEMILZSUCHTZUVERTREIBENUNDDENKREISLAUFINSCHWUNGZUBRINGENIMMERWENNICHMERKEDASSICHUMDENMUNDHERUMGRIMMIGWERDEIMMERWENNINMEINERSEELENASSERNIESLIGERNOVEMBERHERRSCHTIMMERWENNICHMERKEDASSICHVORSARGLAGERNSTEHENBLEIBEUNDJEDEMLEICHENZUGHINTERHERTROTTEDERMIRBEGEGNETUNDBESONDERSIMMERDANNWENNMEINESCHWARZEGALLESOSEHRUBERHANDNIMMTDASSNURSTARKEMORALISCHEGRUNDSATZEMICHDAVONABHALTENKONNENMITVORSATZAUFDIESTRASSEZUTRETENUNDDENLEUTENMITBEDACHTDIEHUTEVOMKOPFZUHAUENDANNISTESHOCHSTEZEITFURMICHSOBALDICHKANNAUFSEEZUKOMMEN',

      cipherText:
        'VPMJZZASZOAVPEDEOVACMIPXODPQIPVRZMQQVMZCWZOZDDVPXOIVMPQYPEQZATVPMQVRWPNYVCDVRCMWZMRZMWAVPGDVNPXOMPXODEYVELMWVAVECMWELWZXODPXONPAPXOILRRDVPMIVMPQOVACNEVQVRMCMWNPAWVMIZEEVAPQVMDVPRWVAIVRDYVEVOVMWZEPEDELNVPMVZADNPAWPVNPRGECXODGCBVADAVPYVMCMWWVMTAVPERZCUPMEXOICMQGCYAPMQVMPNNVAIVMMPXONVATVWZEEPXOCNWVMNCMWOVACNQAPNNPQIVAWVPNNVAIVMMPMNVPMVAEVVRVMZEEVAMPVERPQVAMLBVNYVAOVAAEXODPNNVAIVMMPXONVATVWZEEPXOBLAEZAQRZQVAMEDVOVMYRVPYVCMWSVWVNRVPXOVMGCQOPMDVAOVADALDDVWVANPAYVQVQMVDCMWYVELMWVAEPNNVAWZMMIVMMNVPMVEXOIZAGVQZRRVELEVOACYVAOZMWMPNNDWZEEMCAEDZATVNLAZRPEXOVQACMWEZDGVNPXOWZBLMZYOZRDVMTLMMVMNPDBLAEZDGZCUWPVEDAZEEVGCDAVDVMCMWWVMRVCDVMNPDYVWZXODWPVOCDVBLNTLJUGCOZCVMWZMMPEDVEOLXOEDVGVPDUCANPXOELYZRWPXOTZMMZCUEVVGCTLNNVM',

      // key: zyxwvuqopstrnmljkaedcbifhg
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Der kleine Prinz',

      language: ProblemLanguages.DE,

      plainText:
        'SOLEBTEICHALLEINOHNEJEMANDENMITDEMICHWIRKLICHHATTESPRECHENKONNENBISICHVORSECHSJAHRENEINEPANNEINDERWUSTESAHARAHATTEIRGENDETWASANMEINEMMOTORWARKAPUTTGEGANGENUNDWEILICHWEDEREINENMECHANIKERNOCHPASSAGIEREBEIMIRHATTEBEREITETEICHMICHDARAUFVORDIESCHWIERIGEREPARATURGANZALLEINVORZUNEHMENDABEIGINGESFURMICHUMLEBENUNDTODICHHATTETRINKWASSERFURKNAPPACHTTAGEAMERSTENABENDSCHLIEFICHIMSANDEINTAUSENDEMEILENENTFERNTVONBESIEDELTEMGEBIETICHWARVIELABGESCHIEDENERALSEINSCHIFFBRUCHIGERMITTENIMOZEANSOKONNTIHREUCHVORSTELLENWIEUBERRASCHTICHWARALSICHBEISONNENAUFGANGVONEINERLEISENLUSTIGENSTIMMEGEWECKTWURDE',

      cipherText:
        'TMODIZDJHBGOODJLMBLDPDKGLFDLKJZFDKJHBYJSNOJHBBGZZDTRSDHBDLNMLLDLIJTJHBXMSTDHBTPGBSDLDJLDRGLLDJLFDSYWTZDTGBGSGBGZZDJSCDLFDZYGTGLKDJLDKKMZMSYGSNGRWZZCDCGLCDLWLFYDJOJHBYDFDSDJLDLKDHBGLJNDSLMHBRGTTGCJDSDIDJKJSBGZZDIDSDJZDZDJHBKJHBFGSGWEXMSFJDTHBYJDSJCDSDRGSGZWSCGLAGOODJLXMSAWLDBKDLFGIDJCJLCDTEWSKJHBWKODIDLWLFZMFJHBBGZZDZSJLNYGTTDSEWSNLGRRGHBZZGCDGKDSTZDLGIDLFTHBOJDEJHBJKTGLFDJLZGWTDLFDKDJODLDLZEDSLZXMLIDTJDFDOZDKCDIJDZJHBYGSXJDOGICDTHBJDFDLDSGOTDJLTHBJEEISWHBJCDSKJZZDLJKMADGLTMNMLLZJBSDWHBXMSTZDOODLYJDWIDSSGTHBZJHBYGSGOTJHBIDJTMLLDLGWECGLCXMLDJLDSODJTDLOWTZJCDLTZJKKDCDYDHNZYWSFD',

      // key: gihfdecbjpnoklmrqstzwxyuva
    },
    {
      type: ProblemTypes.SUBSTITUTION,

      title: 'Sherlock Holmes',

      language: ProblemLanguages.DE,

      plainText:
        'SHERLOCKHOLMESWARWIEVERWANDELTWENNERAUFEINESOLCHESPURSTIESSMENSCHENDIENURDENSTILLENDENKERUNDLOGIKERAUSDERBAKERSTREETKANNTENHTTENIHNNICHTERKANNTSEINGESICHTERRTETEUNDVERDUNKELTESICHSEINEBRAUENWARENZUZWEIHARTENSCHWARZENLINIENGEZOGENWHRENDSEINEAUGENDARUNTERMITEINEMSTHLERNENGLITZERNHERVORLEUCHTETENSEINGESICHTWARNACHUNTENGEBOGENDIESCHULTERNGEBEUGTDIELIPPENZUSAMMENGEPRESSTUNDDIEADERNTRATENWIEPEITSCHENSCHNREINSEINEMLANGENSEHNIGENHALSHERVORSEINENASENLCHERSCHIENENSICHMITEINERREINTIERISCHENLUSTANDERJAGDZUWEITENUNDSEINGEISTWARSOABSOLUTAUFDIESACHEKONZENTRIERTDIEERVORSICHHATTEDASSEINEFRAGEODERBEMERKUNGUNGEHRTBLIEBODERHCHSTENSEINSCHNELLESUNGEDULDIGESKNURRENALSANTWORTHERVORRIEFZGIGUNDLAUTLOSSCHLUGERDENWEGEINDERDURCHDIEWIESENUNDDAMITDURCHDENWALDZUMBOSCOMBEPOOLFHRTEESWARFEUCHTERSUMPFIGERBODENWIEBERALLINDIESERGEGENDUNDESGABVIELEFUSSSPURENSOWOHLAUFDEMWEGALSAUCHINDEMKURZENGRASDASIHNAUFBEIDENSEITENBEGRENZTEMANCHMALEILTEHOLMESWEITERMANCHMALBLIEBERSTEHENUNDEINMALMACHTEEREINENKLEINENUMWEGBERDIEWIESE',

      cipherText:
        'FQLIVSKWQSVULFANIARLDLIANTMLVGALTTLINEOLRTLFSVKQLFYEIFGRLFFULTFKQLTMRLTEIMLTFGRVVLTMLTWLIETMVSPRWLINEFMLIJNWLIFGILLGWNTTGLTQGGLTRQTTRKQGLIWNTTGFLRTPLFRKQGLIIGLGLETMDLIMETWLVGLFRKQFLRTLJINELTANILTHEHALRQNIGLTFKQANIHLTVRTRLTPLHSPLTAQILTMFLRTLNEPLTMNIETGLIURGLRTLUFGQVLITLTPVRGHLITQLIDSIVLEKQGLGLTFLRTPLFRKQGANITNKQETGLTPLJSPLTMRLFKQEVGLITPLJLEPGMRLVRYYLTHEFNUULTPLYILFFGETMMRLNMLITGINGLTARLYLRGFKQLTFKQTILRTFLRTLUVNTPLTFLQTRPLTQNVFQLIDSIFLRTLTNFLTVKQLIFKQRLTLTFRKQURGLRTLIILRTGRLIRFKQLTVEFGNTMLIXNPMHEALRGLTETMFLRTPLRFGANIFSNJFSVEGNEOMRLFNKQLWSTHLTGIRLIGMRLLIDSIFRKQQNGGLMNFFLRTLOINPLSMLIJLULIWETPETPLQIGJVRLJSMLIQKQFGLTFLRTFKQTLVVLFETPLMEVMRPLFWTEIILTNVFNTGASIGQLIDSIIRLOHPRPETMVNEGVSFFKQVEPLIMLTALPLRTMLIMEIKQMRLARLFLTETMMNURGMEIKQMLTANVMHEUJSFKSUJLYSSVOQIGLLFANIOLEKQGLIFEUYORPLIJSMLTARLJLINVVRTMRLFLIPLPLTMETMLFPNJDRLVLOEFFFYEILTFSASQVNEOMLUALPNVFNEKQRTMLUWEIHLTPINFMNFRQTNEOJLRMLTFLRGLTJLPILTHGLUNTKQUNVLRVGLQSVULFALRGLIUNTKQUNVJVRLJLIFGLQLTETMLRTUNVUNKQGLLILRTLTWVLRTLTEUALPJLIMRLARLFL',

      // key: njkmlopqrxwvutsyzifgedabch
    },
  ],
};
